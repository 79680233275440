// All known Hyperia API endpoints

export const BASE_URI: string | undefined =
  process.env.NODE_ENV === 'production'
    ? 'https://api.hyperia.space'
    : process.env.REACT_APP_BASE_URI ?? 'localhost:8080'
export const COMMUNITIES_URI =
  process.env.REACT_APP_COMMUNITIES_URI ?? `${BASE_URI}/communities/v1`
export const USERS_URI =
  process.env.REACT_APP_USERS_URI ?? `${BASE_URI}/users/v1`
export const AUTH_URI = process.env.REACT_APP_AUTH_URI ?? `${BASE_URI}/auth/v1`
export const CDN_URI = process.env.REACT_APP_CDN_URI ?? `${BASE_URI}/cdn/v1`
export const HRE_URI = process.env.REACT_APP_HRE_URI ?? `${BASE_URI}/hre/v1`

export const COMMUNITY_ROUTE = (id: string) => `${COMMUNITIES_URI}/${id}`
export const NEW_COMMUNITY_ROUTE = `${COMMUNITIES_URI}/new`
export const COMMUNITY_RATE_ROUTE = (id: string) =>
  `${COMMUNITY_ROUTE(id)}/rate`
export const TRANSFER_COMMUNITY_ROUTE = (id: string) =>
  `${COMMUNITY_ROUTE(id)}/transfer`
export const OWNED_COMMUNITIES_ROUTE = (id: string) =>
  `${COMMUNITIES_URI}/owned/${id}`
export const COMMUNITY_VIEW_ROUTE = (id: string) =>
  `${COMMUNITY_ROUTE(id)}/view`

export const USER_ROUTE = (id: string) => `${USERS_URI}/${id}`
export const REGISTER_ROUTE = `${USERS_URI}/new`
export const FIND_USER_ROUTE = `${USERS_URI}/find`
export const APPEND_HISTORY_ROUTE = `${USERS_URI}/history/@me`

export const CONNECTIONS_ROUTE = (user: string) =>
  `${USERS_URI}/${user}/connections`
export const CONNECTION_ROUTE = (user: string, provider: string) =>
  `${CONNECTIONS_ROUTE(user)}/${provider}`
export const CONNECTION_AUTHENTICATION_ROUTE = (
  user: string,
  provider: string
) => `${CONNECTION_ROUTE(user, provider)}/authenticate`

export const LOGIN_ROUTE = `${AUTH_URI}/login`
export const REFRESH_TOKENS_ROUTE = `${AUTH_URI}/refresh`
export const REVOKE_TOKENS_ROUTE = `${AUTH_URI}/revoke`
export const VALIDATE_TOKENS_ROUTE = `${AUTH_URI}/validate`

export const USER_AVATAR_ROUTE = (id: string) => `${CDN_URI}/avatars/${id}`
export const COMMUNITY_ICON_ROUTE = (id: string) => `${CDN_URI}/icons/${id}`

export const RECOMMEND_ROUTE = (user: string) => `${HRE_URI}/${user}`
