import React from 'react'
import { Col, Container, Row } from 'react-grid-system'
import Box from '../common/components/Box'
import Banner from '../common/components/Banner'

import { NewCommunityForm } from '../common/components/CommunityInfoForm'
import Footer from '../common/components/Footer'

export default function CreateCommunityPage() {
  return (
    <>
      <Banner
        title="Добавить сообщество"
        content=" Добавь свое сообщество к нам для его продвижения"
      />
      <Container fluid>
        <Row>
          <Col md={6} xl={6} offset={{ md: 3, xl: 3 }}>
            <NewCommunityForm />
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  )
}
