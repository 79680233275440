import React, { useEffect, useRef, useState } from 'react'
import styles from './LoginWindow.module.scss'
import Input from '../../common/components/Input'
import useInput from '../../common/hooks/useInput'
import Button from '../../common/components/Button'
import { Redirect, Link, useHistory } from 'react-router-dom'
import { useAuthState } from '../../common/features/auth/state/provider'
import { setPair } from '../../common/features/auth/state/actions'
import { useAPI } from '../../common/features/api/provider'
import { LOGIN_ROUTE, USER_ROUTE } from '../../common/features/api'
import {
  ErrorCode,
  isRESTError,
  RESTError,
} from '../../common/features/api/requester'
import { ErrorWrapper } from '../../common/components/ErrorWrapper'
import { User } from '../../common/features/users'
import { useUser } from '../../common/features/users/provider'

export default function LoginWindow() {
  const [login, handleLogin] = useInput()
  const [password, handlePass] = useInput()
  const [validity, setValidity] = useState(true)
  const [, dispatch] = useAuthState()
  const api = useAPI()
  const history = useHistory()
  const [user, setUser] = useUser()

  const fetchTokens = async () => {
    try {
      const { accessToken, refreshToken } = (
        await api.request<{
          accessToken: string
          refreshToken: string
        }>('POST', LOGIN_ROUTE, { json: { login, password }, omitToken: true })
      ).data

      setUser(
        (
          await api.request<User>('GET', USER_ROUTE('@me'), {
            token: accessToken,
          })
        ).data
      )
      dispatch(setPair(accessToken, refreshToken))
      history.push('/user')
    } catch (e) {
      if (
        isRESTError(e) &&
        (e as RESTError).code === ErrorCode.INVALID_CREDENTIALS
      ) {
        setValidity(false)
      } else {
        throw e
      }
    }
  }

  useEffect(() => {
    if (user) history.push('/user')
  }, [user])

  return (
    <div className={`${styles.loginWindow} shadow animation-slidein-bounce`}>
      <h1
        className="text-md text-bold"
        style={{ textAlign: 'center', marginBottom: '50px' }}
      >
        Вход
      </h1>
      <ErrorWrapper
        message={validity ? undefined : 'Неправильный логин или пароль'}
        position="after"
      >
        <Input placeholder="Никнейм или почта" onChange={handleLogin} fluid />
        <Input
          placeholder="Пароль"
          onChange={handlePass}
          fluid
          marginTop="24px"
          type="password"
        />
      </ErrorWrapper>
      <Button
        styles={{ width: '100%', marginTop: '24px' }}
        onClick={fetchTokens}
      >
        Войти
      </Button>
      <div className={`text-greyple ${styles.loginWindowRegister}`}>
        {/* Нет аккаунта? - <Link to="/register">Зарегистрируйтесь!</Link> */}
        Нет аккаунта? - <Link to="/register">Зарегистрироваться!</Link>
      </div>
    </div>
  )
}
