import React, { useEffect, useState } from 'react'
import { Container, Col, Row } from 'react-grid-system'
import { Link, useHistory, useParams } from 'react-router-dom'
import {
  Badges,
  BadgeType,
  Community,
  CommunityPlatform,
} from '../common/features/communities'
import {
  APPEND_HISTORY_ROUTE,
  COMMUNITY_ROUTE,
  COMMUNITY_VIEW_ROUTE,
} from '../common/features/api/endpoints'
import { useAPI } from '../common/features/api/provider'
import { Avatar } from './components/Avatar'
import { Banner } from './components/Banner'
import { RatingWindow } from './components/RatingWindow'
import styles from './CommunityPage.module.scss'
import Button from '../common/components/Button'
import Box from '../common/components/Box'
import { ControlPanel } from './components/ControlPanel'
import { Tabs } from './components/Tabs'
import { ErrorCode, isRESTError, RESTError } from '../common/features/api'
import { useAuthState } from '../common/features/auth/state/provider'
import { useUser } from '../common/features/users/provider'
import { User } from '../common/features/users'
import { resolveIcon } from '../common/features/communities/avatar'
import Footer from '../common/components/Footer'

export function CommunityPage() {
  const [info, setInfo] = useState<Community>({
    id: '',
    name: 'Название',
    desc: 'Описание',
    avatar_url: resolveIcon({}),
    link: '',
    tags: [],
    badges: 0,
    kind: 'discord',
    owner: '',
    average_rate: 0,
    preferred_language: 'ru',
  })
  const api = useAPI()
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const tokens = useAuthState()[0]
  const me = useUser()[0]

  const fetchData = async () => {
    try {
      const data = (await api.request<Community>('GET', COMMUNITY_ROUTE(id)))
        .data
      data.tags = data.tags ?? []
      data.badges = data.badges ?? 0
      setInfo(data)
    } catch (e) {
      if (
        isRESTError(e) &&
        (e as RESTError).code === ErrorCode.UNKNOWN_COMMUNITY
      ) {
        history.push('/')
      }
    }
  }
  const ackView = async () => {
    await api.request<{}>('PUT', COMMUNITY_VIEW_ROUTE(id))
    await api.request<{}>('POST', APPEND_HISTORY_ROUTE, {
      json: { id },
    })
  }

  const editable = () =>
    Boolean(me && (me.id === info.owner || ((me.perms || 0) & 0x1) !== 0))

  useEffect(() => {
    fetchData()
    ackView()
  }, [])

  return (
    <>
      <Banner image="https://media.discordapp.net/attachments/767389299037437952/927314860793036850/thumb-1920-909912.png" />
      <Container className="hidden-lg fill-height">
        <Row className={styles.communityPageContent}>
          <Col
            lg={3}
            md={3}
            className={styles.communityPageCol}
            style={{ top: '-38px' }}
          >
            <Avatar url={resolveIcon(info)} />
            <RatingWindow rating={info.average_rate} marginTop={16} maxWidth={280} />
          </Col>
          <Col lg={4} xl={4} xxl={5} className={styles.communityPageCol}>
            <h1
              className="text-xl"
              style={{ fontSize: '70px', marginTop: -14 }}
            >
              {info.name}
            </h1>
            <p
              className="text-sm"
              style={{ overflowWrap: 'break-word', marginTop: 48 }}
            >
              {info.desc}
            </p>
          </Col>
          <Col
            className={styles.communityPageCol}
            md={4}
            lg={4}
            xl={4}
            xxl={3}
            offset={{ lg: 1, xxl: 1 }}
          >
            <Button
              color="brand"
              className={'text-sm'}
              onClick={() => (window.location.href = info.link)}
              styles={{ width: '100%' }}
            >
              Зайти
            </Button>
            <Box style={{ marginTop: 24, display: 'flex' }}>
              <ControlPanel
                id={id}
                tags={info.tags}
                badges={Badges.reduce(
                  (p, v) => (info.badges & v ? [...p, v] : p),
                  [] as BadgeType[]
                )}
                owner={editable()}
                language={info.preferred_language}
              />
            </Box>
          </Col>
        </Row>
      </Container>
      <div className={`show-lg ${styles.mobileInfo}`}>
        <Avatar url={resolveIcon(info)} style={{ width: 280 }} />
        <h1 className="text-xl">{info.name}</h1>
        <RatingWindow rating={info.average_rate} width={280} />
        <Button
          color="brand"
          className={'text-sm'}
          onClick={() => (window.location.href = info.link)}
          styles={{ width: '100%' }}
        >
          Зайти
        </Button>
        <Tabs
          styles={{
            tabs: { width: '100%' },
            tab: { justifyContent: 'center' },
          }}
          tabs={[
            [
              'Описание',
              <p style={{ overflowWrap: 'break-word' }}>{info.desc}</p>,
            ],
            [
              'Информация',
              <ControlPanel
                id={id}
                tags={info.tags}
                badges={Badges.reduce(
                  (p, v) => (info.badges & v ? [...p, v] : p),
                  [] as BadgeType[]
                )}
                owner={editable()}
                language={info.preferred_language}
              />,
            ],
          ]}
        />
      </div>
      <Footer />
    </>
  )
}
