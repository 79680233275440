import React from 'react'
import styles from './Avatar.module.scss'

type AvatarProps = {
  url?: string
  style?: React.CSSProperties
}

export function Avatar({ url, style }: AvatarProps) {
  return (
    <img
      src={url}
      alt="Аватарка"
      className={styles.communityAvatar}
      style={style}
    />
  )
}
