import React from 'react'
import styles from './Banner.module.scss'

type BannerProps = {
  image: string
  width?: number | string
  height?: number | string
}

const backgroundFadeout = (image: string) =>
  `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.291667) 47.4%, rgba(255, 255, 255, 0.488036) 72.4%, rgba(255, 255, 255, 0.67157) 83.33%, #FFFFFF 100%), url(${image}) repeat-y`

export function Banner({ image, width, height }: BannerProps) {
  return (
    <div
      className={styles.communityBanner}
      style={{
        background: backgroundFadeout(image),
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
      }}
    />
  )
}
