import React from 'react'
import styles from './LoadingAnimation.module.scss'

type LoadingAnimationProps = {
  duration: number
}

export function LoadingAnimation({ duration }: LoadingAnimationProps) {
  const individualDuration = duration / 3
  return (
    <div className={styles.loadingAnimationWrapper}>
      {Array.from(Array(3).keys(), (v) => (
        <div
          className={styles.loadingAnimationElement}
          style={{
            animationDelay: `${v * individualDuration}ms`,
            animationDuration: `${duration}ms`,
          }}
        ></div>
      ))}
    </div>
  )
}
