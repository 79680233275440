import React, { PropsWithChildren } from 'react'
import Box from '../../common/components/Box'
import styles from './StarRoadCard.module.scss'

export type StarRoadCardProps = {
  nick: string
  desc?: string
  avatarURL: string
  publicURL?: string
}

export function StarRoadCard({
  nick,
  desc,
  publicURL,
  avatarURL,
}: StarRoadCardProps) {
  const card = (
    <Box className={styles.starRoadCard}>
      <img
        src={avatarURL}
        alt="Аватар"
        className={styles.starRoadCardAvatar}
        height="73"
        width="73"
      />
      <h2 className={`text-md text-medium ${styles.starRoadCardNick}`}>
        {nick}
      </h2>
      <p className={`text-sm ${styles.starRoadCardDesc}`}>{desc}</p>
    </Box>
  )

  // eslint-disable-next-line multiline-ternary
  return publicURL ? (
    <a href={publicURL} style={{ all: 'unset', cursor: 'pointer' }}>
      {card}
    </a>
  ) : (
    <div style={{ cursor: 'default' }}>{card}</div>
  )
}
