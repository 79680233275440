import React, { useContext, useState } from 'react'
import styles from './Signup.module.scss'
import Input from '../../common/components/Input'
import useInput from '../../common/hooks/useInput'
import Button from '../../common/components/Button'
import { Link, useHistory } from 'react-router-dom'
import { ErrorWrapper } from '../../common/components/ErrorWrapper'
import {
  FIND_USER_ROUTE,
  REGISTER_ROUTE,
  USER_ROUTE,
} from '../../common/features/api'
import { useAPI } from '../../common/features/api/provider'
import { RegisterInfo } from '../../common/features/users'
import {
  AuthContext,
  useAuthState,
} from '../../common/features/auth/state/provider'
import {
  ErrorCode,
  isRESTError,
  Requester,
  RESTError,
} from '../../common/features/api/requester'
import { Select } from '../../common/components/SelectWrapper'
import { TagTypes } from '../../common/features/communities'
import InputOutlined from '../../common/components/InputOutlined'
import Field from '../../common/components/Field'
import { TagSelect } from '../../common/components/TagSelect'

type Inputs = {
  nick: string
  email: string
  pass: string
}

type InputsErrors = {
  [P in keyof Inputs]?: string
}

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i

function validateInputs({ nick, email, pass }: Inputs): InputsErrors {
  const errors: InputsErrors = {}

  if (!nick || nick.length === 0) errors.nick = 'Пустой никнейм'

  if (!email || !email.match(emailRegex)) {
    errors.email = 'Неправильный адрес электронной почты'
  }

  if (!pass || pass.length < 5) errors.pass = 'Недостаточно длинный пароль'

  return errors
}
export default function SignupWindow() {
  const [nick, handleNick] = useInput()
  const [pass, handlePass] = useInput()
  const [email, handleEmail] = useInput()
  const [preferences, setPreferences] = useState<TagTypes[]>([])
  const [errors, setErrors] = useState<InputsErrors>()
  const api = useAPI()
  const history = useHistory()

  const onSubmit = async () => {
    const errors = validateInputs({ nick, email, pass })
    if (errors.nick || errors.email || errors.pass) setErrors(errors)
    else {
      try {
        await api.request<{ id: string }>('POST', REGISTER_ROUTE, {
          json: {
            nick,
            email,
            password: pass,
            preferences,
          } as RegisterInfo,
        })
        history.push('/login')
      } catch (e) {
        if (isRESTError(e) && (e as RESTError).code === ErrorCode.NICK_TAKEN) {
          errors.nick = 'Такой никнейм уже используется'
          setErrors(errors)
        } else {
          throw e
        }
      }
    }
  }

  return (
    <div className={`${styles.signupWindow} shadow animation-slidein-bounce`}>
      <h1
        className="text-md text-bold"
        style={{ textAlign: 'center', marginBottom: '50px' }}
      >
        Регистрация
      </h1>
      <ErrorWrapper message={errors?.nick} position="after">
        <Field name="Никнейм">
          <InputOutlined
            placeholder=""
            onChange={handleNick}
            value={nick ?? ''}
            fluid
          />
        </Field>
      </ErrorWrapper>
      <ErrorWrapper
        message={errors?.email}
        position="after"
        style={{ marginTop: 24 }}
      >
        <Field name="Электронная почта">
          <InputOutlined
            placeholder=""
            onChange={handleEmail}
            value={email ?? ''}
            fluid
          />
        </Field>
      </ErrorWrapper>
      <ErrorWrapper
        message={errors?.pass}
        position="after"
        style={{ marginTop: 24 }}
      >
        <Field name="Пароль">
          <InputOutlined
            placeholder=""
            type="password"
            onChange={handlePass}
            fluid
          />
        </Field>
      </ErrorWrapper>
      <Field name="Предпочтения" style={{ marginTop: 24 }}>
        <TagSelect
          selected={preferences}
          setSelected={setPreferences}
          maximum={5}
        />
      </Field>
      <Button styles={{ width: '100%', marginTop: '24px' }} onClick={onSubmit}>
        Зарегистрироваться
      </Button>
      <div className={`text-greyple ${styles.signupWindowLogin}`}>
        {/* Уже есть аккаунт? - <Link to="/login">Войдите!</Link> */}
        У меня уже есть аккаунт - <Link to="/login">Войти!</Link>
        {/* <Link to="/login">Вернуться на страницу входа</Link> */}
      </div>
    </div>
  )
}
