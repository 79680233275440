import React, { PropsWithChildren } from 'react'
import { PropsWithStyle } from '../utils/style'
import styles from './Field.module.scss'

type FieldProps = PropsWithChildren<{
  name: string
  style?: React.CSSProperties
}>

export default function Field({ name, style, children }: FieldProps) {
  return (
    <div className={styles.field} style={style}>
      <p className={`text-sm text-medium ${styles.fieldLabel}`}>{name}</p>
      <div className={styles.fieldContent}>{children}</div>
    </div>
  )
}

export function InlineFieldContainer({
  style,
  children,
}: PropsWithChildren<{ style?: React.CSSProperties }>) {
  return (
    <div className={styles.inlineFieldContainer} style={style}>
      {children}
    </div>
  )
}
export function InlineFields({
  style,
  children,
}: PropsWithChildren<PropsWithStyle<{}>>) {
  return (
    <div className={styles.inlineFields} style={style}>
      {children}
    </div>
  )
}
