import axios from 'axios'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import Button from '../../common/components/Button'
import Input from '../../common/components/Input'
import InputOutlined from '../../common/components/InputOutlined'
import {
  CONNECTIONS_ROUTE,
  CONNECTION_ROUTE,
  USER_AVATAR_ROUTE,
  USER_ROUTE,
} from '../../common/features/api'
import { useAPI } from '../../common/features/api/provider'
import {
  Connection,
  ConnectionProvider,
  Connections,
  User,
} from '../../common/features/users'
import useInput from '../../common/hooks/useInput'
import { ConnectionButton } from './OAuth2Connection'
import styles from './UserSettingsWindow.module.scss'
import discordLogo from './assets/discord.svg'
import ImageButton, {
  UploadableImageButton,
} from '../../common/components/ImageButton'
import { PropsWithStyle } from '../../common/utils/style'
import { useUser } from '../../common/features/users/provider'
import Field, {
  InlineFieldContainer,
  InlineFields,
} from '../../common/components/Field'
import { Select } from '../../common/components/SelectWrapper'
import { TagTypes } from '../../common/features/communities/tags'
import { TagSelect } from '../../common/components/TagSelect'
import SaveButton from '../../common/components/SaveButton'

export function UserSettingsWindow() {
  const [nickname, handleNickname] = useInput()
  const [email, handleEmail] = useInput()
  const [password, handlePassword] = useInput()
  const [avatarUrl, setAvatarUrl] = useState<string>()
  const [avatarFile, setAvatarFile] = useState<File>()
  const [connectionsIdentities, setConnectionsIdentities] = useState<
    Record<ConnectionProvider, string | undefined>
  >({} as Record<ConnectionProvider, string | undefined>)
  const [preferences, setPreferences] = useState<TagTypes[]>()
  const api = useAPI()
  const [me] = useUser()

  const identityFetchers: Record<
    ConnectionProvider,
    (connection: Connection) => Promise<string>
  > = {
    discord: async ({ oauth2 }: Connection): Promise<string> => {
      const request = await axios.get('https://discord.com/api/v9/users/@me', {
        headers: {
          Authorization: `${oauth2?.tokenType} ${oauth2?.accessToken}`,
        },
      })
      if (request.status === 401) {
        // TODO: test this on production or with alt account
        const me = (await api.request<User>('GET', USER_ROUTE('@me'))).data
        await api.request<unknown>('DELETE', CONNECTION_ROUTE(me.id, 'discord'))
      }
      const { username, discriminator } = request.data
      return username + '#' + discriminator
    },
  }

  const fetchUserData = async () => {
    handleNickname(me?.nick as string)
    handleEmail(me?.email as string)
    setAvatarUrl(me?.avatarUrl as string)
    setPreferences(me?.preferences)

    const connections =
      (
        await api.request<Connections>(
          'GET',
          CONNECTIONS_ROUTE(me?.id as string)
        )
      ).data.connections ||
      ({} as Record<ConnectionProvider, Connection | undefined>)
    const identities = {} as Record<ConnectionProvider, string | undefined>

    for (const [provider, connection] of Object.entries(connections)) {
      if (connection) {
        identities[provider as ConnectionProvider] = await identityFetchers[
          provider as ConnectionProvider
        ](connection)
      }
    }
    setConnectionsIdentities(identities)
  }
  useEffect(() => {
    if (!me) return
    fetchUserData()
  }, [me])
  const onSubmit = async () => {
    console.log(preferences)
    const data: Partial<User> = {
      nick: nickname,
      email,
      password,
      preferences,
    }
    if (avatarFile) {
      const formData = new FormData()
      formData.set('file', avatarFile, avatarFile.name)
      await api.request<unknown>(
        'POST',
        USER_AVATAR_ROUTE(
          me?.id ??
            (await (await api.request<{ id: string }>('GET', USER_ROUTE('@me')))
              .data.id) // TODO: useUser() hook & context
        ),
        {
          formData,
        }
      )
      data.avatarUrl = USER_AVATAR_ROUTE(
        me?.id ??
          (await (await api.request<{ id: string }>('GET', USER_ROUTE('@me')))
            .data.id)
      )
    }
    await api.request<User>('PUT', USER_ROUTE('@me') + '?avoidEmpty=true', {
      json: data,
    })

    if (preferences && preferences?.length === 0) {
      // FIXME: remove this hack
      const d = (await api.request<User>('GET', USER_ROUTE('@me'))).data
      d.preferences = preferences
      await api.request<User>('PUT', USER_ROUTE('@me'), { json: d })
    }
  }

  return (
    <div className={`${styles.userSettingsWindow} animation-slidein-bounce`}>
      <h1 className="text-md text-bold">Настройки аккаунта</h1>
      <InlineFieldContainer>
        <Field name="Аватарка:">
          <UploadableImageButton
            /* eslint-disable-next-line @typescript-eslint/no-empty-function */
            onFileUploaded={(f) => {
              const reader = new FileReader()
              reader.readAsDataURL(f)
              reader.onloadend = (_) => {
                setAvatarUrl(reader.result as string)
              }
              setAvatarFile(f)
            }}
            accept={['image/*']}
            image={
              avatarUrl ?? 'https://api.hyperia.space/cdn/v1/embed/avatar.svg'
            }
            width={148}
            height={148}
            imageStyle={{ borderRadius: '34px' }}
          />
        </Field>
        <InlineFields style={{ justifyContent: 'space-between' }}>
          <Field name="Никнейм:">
            <InputOutlined onChange={handleNickname} value={nickname} fluid />
          </Field>
          <Field name="Почта:">
            <InputOutlined onChange={handleEmail} value={email} fluid />
          </Field>
        </InlineFields>
      </InlineFieldContainer>
      <Field name="Новый пароль:" style={{ marginTop: 24 }}>
        <InputOutlined onChange={handlePassword} type="password" fluid />
      </Field>
      {/* <p className="text-sm text-medium">Предпочтения:</p> */}
      {/* {tags.map(e => <Tag key={e} onChange={newS => setTag(e, newS)}>{e}</Tag>)} */}
      <Field name="Предпочтения" style={{ marginTop: 24 }}>
        <TagSelect
          selected={preferences}
          setSelected={setPreferences}
          maximum={5}
        />
      </Field>
      <Field name="Интеграции:" style={{ marginTop: 24 }}>
        <div className={styles.userSettingsConnections}>
          <ConnectionButton
            provider={ConnectionProvider.Discord}
            logo={discordLogo}
            setIdentity={(identity?: string) => {
              setConnectionsIdentities({
                ...connectionsIdentities,
                discord: identity,
              })
            }}
            identity={connectionsIdentities.discord}
          />
        </div>
      </Field>
      <SaveButton onClick={onSubmit} styles={{ marginTop: 24 }}>
        Сохранить
      </SaveButton>
    </div>
  )
}
