import React, { PropsWithChildren, useState } from 'react'
import { Container, Row, Col, Visible } from 'react-grid-system'
import navbarCss from './Navbar.module.scss'
import Logo from './Logo'
import login from '../assets/login.svg'
import { Link, useHistory } from 'react-router-dom'
import useAppendClassname from '../hooks/useAppendClassname'
import burger from '../assets/hamburger.svg'
import burgerLight from '../assets/burger.svg'
import ImageButton from './ImageButton'
import Button from './Button'
import { useAuthState } from '../features/auth/state/provider'
import myCommunitiesIcon from '../assets/navbar-menu/my-communities.svg'
import settingsIcon from '../assets/navbar-menu/settings.svg'
import logoutIcon from '../assets/navbar-menu/logout.svg'

import { useUser } from '../features/users/provider'
import { logout } from '../features/auth/state/actions'
import classNames from 'classnames'

export type NavbarUserProps = PropsWithChildren<{
  className?: string
  onClick?: () => void
  onMouseEnter?: () => void
  compact?: boolean
}>

export function NavbarUser({
  className,
  onClick,
  onMouseEnter,
  compact,
}: NavbarUserProps) {
  const [user] = useUser()
  const history = useHistory()
  return (
    <>
      {/* eslint-disable-next-line multiline-ternary */}
      {user ? (
        <img
          className={
            user
              ? useAppendClassname(navbarCss.navbarAvatar, className)
              : className
          }
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          src={
            user.avatarUrl ??
            'https://api.hyperia.space/cdn/v1/embed/avatar.svg'
          }
          alt="Аватар"
        />
      ) : compact ? (
        <Link to="/login" className={navbarCss.navbarCompactButton}>
          <img src={login} />
        </Link>
      ) : (
        <Button
          className={
            user
              ? useAppendClassname(navbarCss.navbarAvatar, className)
              : className
          }
          onClick={() => history.push('/login')}
        >
          Войти
        </Button>
      )}
    </>
  )
}
type NavbarUserMenuItemProps = {
  name: string
  href: string
  icon?: string
}

function NavbarUserMenuItem({ name, icon, href }: NavbarUserMenuItemProps) {
  return (
    <Link className={navbarCss.navbarUserMenuItem} to={href}>
      {icon && (
        <img
          src={icon}
          alt="Значок меню"
          className={navbarCss.navbarUserMenuItemIcon}
        />
      )}
      <p className="text-sm text-bold">{name}</p>
    </Link>
  )
}

export function NavbarUserMenu({ visible }: { visible?: boolean }) {
  const history = useHistory()
  const [, setAuthState] = useAuthState()
  const [, setUser] = useUser()

  return (
    <ul
      className={`${navbarCss.navbarUserMenu} ${!visible ? navbarCss.navbarUserMenuHidden : ''
        }`}
    >
      <NavbarUserMenuItem
        icon={myCommunitiesIcon}
        name="Мои сообщества"
        href="/communities"
      />
      <NavbarUserMenuItem icon={settingsIcon} name="Настройки" href="/user" />
      <div
        className={navbarCss.navbarUserMenuItem}
        onClick={() => {
          setAuthState(logout())
          setUser(undefined)
        }}
      >
        <img
          src={logoutIcon}
          alt="Значок меню"
          className={navbarCss.navbarUserMenuItemIcon}
        />
        <p className="text-sm text-bold">Выйти</p>
      </div>
    </ul>
  )
}

function Links({ links }: { links: [string, string][] }) {
  return (
    <>
      {links.map(([one, two]) =>
        // eslint-disable-next-line multiline-ternary
        two.startsWith('/') ? (
          <Link className="text-sm text-medium header" to={two} key={one + two}>
            {one}
          </Link>
        ) : (
          <a className="text-sm text-medium header" key={one + two} href={two}>
            {one}
          </a>
        )
      )}
    </>
  )
}

export type NavbarMobileMenuProps = PropsWithChildren<{
  links: [string, string][]
  show: boolean
  hideUser?: boolean
}>

export function NavbarMobileMenu({
  links,
  show,
  hideUser,
}: NavbarMobileMenuProps) {
  const [tokens, _] = useAuthState()
  const [menuVisible, setMenuVisible] = useState(false)
  return (
    <div
      className={
        navbarCss.navbarMobileMenu +
        ` ${show
          ? navbarCss.navbarMobileMenuVisible
          : navbarCss.navbarMobileMenuHidden
        }`
      }
    >
      <Links links={links} />
      {!hideUser && (
        <>
          <NavbarUser
            className={
              tokens.accessToken === ''
                ? navbarCss.navbarMobileMenuLogin
                : navbarCss.navbarMobileMenuAvatar
            }
            onClick={() => setMenuVisible(!menuVisible)}
          />
          <NavbarUserMenu visible={menuVisible && tokens.accessToken !== ''} />
        </>
      )}
    </div>
  )
}

export type NavbarProps = PropsWithChildren<{
  links: [string, string][]
}>

export function NavbarCompact({ links }: NavbarProps) {
  const [showMenu, setShowMenu] = useState<boolean>(false)
  return (
    <nav className={classNames(navbarCss.navbar, navbarCss.navbarCompact)}>
      <Container style={{ padding: '0 24px' }}>
        <Row justify="between">
          <Col xs={1} md={1}>
            <Link to="/" style={{ opacity: '1 !important' }}>
              <Logo dark />
            </Link>
          </Col>
          <Col
            xs={3}
            md={2}
            // offset={{ xl: 5 }}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 24,
              alignItems: 'center',
              // paddingRight: 24,
              // padding: 0,
            }}
          >
            <Visible xs sm md lg>
              <ImageButton
                onClick={() => setShowMenu(!showMenu)}
                style={{
                  cursor: 'pointer',
                }}
                image={burger}
                height={32}
                width={32}
              />
            </Visible>
            <Visible xl xxl>
              <div className={classNames(navbarCss.navbarCompactDropdown, { [navbarCss.navbarCompactDropdownActive]: showMenu })}>
                <ImageButton
                  onClick={() => setShowMenu(!showMenu)}
                  style={{
                    cursor: 'pointer',
                  }}
                  imageClassName={classNames(navbarCss.navbarCompactMenuButton, { [navbarCss.navbarCompactMenuButtonActive]: showMenu })}
                  image={burger}
                  height={32}
                  width={32}
                />

                <div className={navbarCss.navbarCompactDropdownContentWrapper}>
                  <div
                    className={classNames(
                      navbarCss.navbarCompactDropdownContent,
                      'shadow'
                    )}
                  >
                    <Links links={links} />
                  </div>
                </div>
              </div>
            </Visible>
            <NavbarUser compact />
          </Col>
        </Row>
        <Visible xs sm md lg>
          <NavbarMobileMenu links={links} show={showMenu} hideUser />
        </Visible>
      </Container>
    </nav >
  )
}

export default function Navbar({ links }: NavbarProps) {
  const [show, setShow] = useState(false)
  const [menuVisible, setMenuVisible] = useState(false)
  const [tokens, _] = useAuthState()
  return (
    <nav className={navbarCss.navbar}>
      <Container className={navbarCss.navbarContent}>
        <Row justify={'between'}>
          <Col xs={1}>
            <Link to="/">
              <Logo />
            </Link>
          </Col>
          <Visible xl xxl>
            <Col xs={10} className={navbarCss.navbarLinkWrapper}>
              <Links links={links} />
            </Col>
            <Col xs={1}>
              <span onMouseLeave={() => setMenuVisible(false)}>
                <NavbarUser onMouseEnter={() => setMenuVisible(true)} />
                <div className={navbarCss.navbarUserMenuWrapper}>
                  <NavbarUserMenu
                    visible={menuVisible && tokens.accessToken !== ''}
                  />
                </div>
              </span>
            </Col>
          </Visible>
          <Visible xs sm md lg>
            <Col
              xs={1}
              sm={1}
              style={{
                marginRight: '24px',
                paddingRight: '24px' /* TODO: think about this */,
              }}
            >
              <ImageButton
                onClick={() => setShow(!show)}
                style={{
                  cursor: 'pointer',
                }}
                image={burgerLight}
                height={32}
                width={32}
              />
            </Col>
          </Visible>
        </Row>
        <Visible xs sm md lg>
          <NavbarMobileMenu links={links} show={show} />
        </Visible>
      </Container>
    </nav>
  )
}
