import React from 'react'
import { APIProvider } from './api/provider'
import { AuthProvider } from './auth/state/provider'
import { UserProvider } from './users/provider'

export const AppStateContext = ({ children }: React.PropsWithChildren<any>) => {
  return (
    <AuthProvider>
      <APIProvider>
        <UserProvider>{children}</UserProvider>
      </APIProvider>
    </AuthProvider>
  )
}
