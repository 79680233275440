import React, { PropsWithChildren } from 'react'
import styles from './QnACard.module.scss'

type QnACardProps = PropsWithChildren<{
  question: string
}>

export function QnACard({ question, children }: QnACardProps) {
  return (
    <article className={`${styles.QnACard} shadow`} key={`q-${question}`}>
      <h1 className={`${styles.QnACardQuestion} text-md`}>{question}</h1>
      <p className={`${styles.QnACardAnswer} text-sm text-medium`}>
        {children}
      </p>
    </article>
  )
}
