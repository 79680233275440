import axios, { AxiosInstance } from 'axios'
import { XOR } from '../../utils/xor'

export type APIMethod = 'POST' | 'GET' | 'DELETE' | 'PUT'
export type RequesterOptions = {
  token?: string
  retryCount?: number
}
export type RequesterHooks = {
  onRequest?: (requester: Requester, request: Request) => Promise<boolean>
  onResponse?: (
    requester: Requester,
    request: Request,
    response: Response<any>
  ) => Promise<void>
  onError?: (
    requester: Requester,
    request: Request,
    response: Response<RESTError>
  ) => Promise<boolean>
}

function isHTTPStatusCodeSuccessful(statusCode: number): boolean {
  return statusCode >= 200 && statusCode <= 299
}

export enum ErrorCode {
  OK = 'OK',

  MISSING_ACCESS = 'MISSING_ACCESS',
  NOT_IMPLEMENTED = 'NOT_IMPLEMENTED',
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL_ERROR = 'INTERNAL_ERROR',

  HTTP_ONLY = 'HTTP_ONLY',

  UNKNOWN_USER = 'UNKNOWN_USER',
  UNKNOWN_COMMUNITY = 'UNKOWN_COMMUNITY',
  UNKNOWN_OWNER = 'UNKNOWN_OWNER',

  NO_UPDATE_PERMISSION = 'NO_UPDATE_PERMISSION',

  AUTHENTICATED_USER_NOT_EXISTS = 'AUTHENTICATED_USER_NOT_EXISTS',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  INVALID_TOKEN_PAIR = 'INVALID_TOKEN_PAIR',
  INVALID_ACCESS_TOKEN = 'INVALID_ACCESS_TOKEN',
  INVALID_REFRESH_TOKEN = 'INVALID_REFRESH_TOKEN',
  AUTHORIZATION_TOKEN_NOT_SUPPLIED = 'AUTHORIZATION_TOKEN_NOT_SUPPLIED',
  INVALID_AUTHORIZATION_TOKEN = 'INVALID_AUTHORIZATION_TOKEN',

  CONNECTION_PROVIDER_NOT_SUPPORTED = 'CONNECTION_PROVIDER_NOT_SUPPORTED',
  CONNECTION_ALREADY_EXISTS = 'CONNECTION_ALREADY_EXISTS',
  CONNECTION_NOT_FOUND = 'CONNECTION_NOT_FOUND',
  INCOMPATIBLE_CONNECTION_DATA = 'INCOMPATIBLE_CONNECTION_DATA',

  NICK_TAKEN = 'NICK_TAKEN',
  CANNOT_CHANGE_USER_PASSWORD = 'CANNOT_CHANGE_USER_PASSWORD',
  CANNOT_CHANGE_USER_PERMISSIONS = 'CANNOT_CHANGE_USER_PERMISSIONS',

  ALREADY_RATED = 'ALREADY_RATED',
}

export class RESTError extends Error {
  constructor(
    public code: ErrorCode,
    message: string,
    public details: RESTErrorDetail[] = []
  ) {
    super(`[${code}] ${message} ${JSON.stringify(details)}`)
    this.name = 'REST'
  }
}

export const isRESTError = (e: any) => e instanceof RESTError

export interface RESTErrorDetail {
  type: 'FIELD_VIOLATION'
  field: string
  message: string
}

export type Response<T> = {
  data: T
  statusCode: number
  request: Request
  successful: boolean
}

export class Request {
  //
  constructor(
    private _axios: AxiosInstance,
    public url: string,
    public method: APIMethod,
    public headers: Record<string, string>,
    public body?: any
  ) {}

  async perform<R>(): Promise<Response<R | RESTError>> {
    const response = await this._axios.request({
      url: this.url,
      method: this.method,
      data: this.body,
      headers: this.headers,
      validateStatus: () => true,
    })
    const successful = isHTTPStatusCodeSuccessful(response.status)
    const result = {
      data: response.data,
      statusCode: response.status,
      request: this,
      successful: successful,
    }
    return successful
      ? (result as Response<R>)
      : (result as Response<RESTError>)
  }
}

export type RequestOptions = {
  omitToken?: boolean
  token?: string
  retry?: number
  bypassHooks?: boolean
} & XOR<{ json?: any }, { formData: FormData }>

export class Requester {
  options: RequesterOptions
  hooks: RequesterHooks // TODO: array hooks
  _axios: AxiosInstance

  constructor(options: RequesterOptions, hooks?: RequesterHooks) {
    this.options = options
    this.hooks = hooks ?? ({} as RequesterHooks)
    this._axios = axios.create({
      // baseURL: BASE_URI,
    })
  }

  headers(options?: RequestOptions): Record<string, string> {
    const headers: Record<string, string> = {}
    if (options) {
      headers['Content-Type'] = options?.formData
        ? 'multipart/form-data'
        : 'application/json'
    }
    if (options?.token) headers.Authorization = options.token
    else if (
      !options?.omitToken &&
      this.options.token &&
      this.options.token !== ''
    ) {
      headers.Authorization = this.options.token
    }
    return headers
  }

  async request<R>(
    method: APIMethod,
    url: string,
    options?: RequestOptions
  ): Promise<Response<R>> {
    if (!options) options = {} as RequestOptions
    const request = new Request(
      this._axios,
      url,
      method,
      this.headers(options),
      options.formData
        ? options.formData
        : options.json
        ? JSON.stringify(options.json)
        : undefined
    )
    if (
      !(options.bypassHooks ?? false) &&
      this.hooks.onRequest &&
      !(await this.hooks.onRequest(this, request))
    ) {
      return {} as Response<R>
    }
    const resp = await request.perform<R>()
    if (!resp.successful) {
      const condition =
        !(options.bypassHooks ?? false) &&
        (!this.hooks.onError ||
          (await this.hooks.onError(
            this,
            request,
            resp as Response<RESTError>
          )))
      if ((options.retry ?? 1) < (this.options.retryCount ?? 0)) {
        options.retry = (options.retry ?? 1) + 1
        return this.request<R>(method, url, options)
      }
      if (condition) {
        const { code, message, details } = resp.data as RESTError
        throw new RESTError(code, message, details)
      }
    }

    return resp as Response<R>
  }
}
