import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import Feed from './feed/Feed'
import LoginPage from './login/LoginPage'
import SignupPage from './signup/SignupPage'
import CommunitiesPage from './communities/CommunitiesPage'
import UserSettingsPage from './user/UserSettingsPage'
import Navbar, { NavbarCompact } from './common/components/Navbar'

import { AppStateContext } from './common/features/combined'
import { OAuth2ConnectionCallback } from './user/components/OAuth2Connection'
import CreateCommunityPage from './new/CreateCommunityPage'
import { CommunityPage } from './community/CommunityPage'
import EditCommunityPage from './edit/EditCommunityPage'
import { LoginCheck } from './common/components/LoginCheck'
import { QnAPage } from './qna/QnAPage'
import { StarRoadPage } from './starroad/StarRoadPage'
import { MainPage } from './main/MainPage'
import { FullscreenLoaderDelay } from './common/components/FullscreenLoader'
import { AboutPage } from './about/AboutPage'

export default function App() {
  const navbarLinks: [string, string][] = [
    ['Мониторинг', '/'],
    ['Star road', '/starroad'],
    ['Новости', 'https://blog.hyperia.space/'],
    ['Статус', 'https://hyperia.statuspage.io'],
    ['Q&A', '/qna'],
    ['Добавить сообщество', '/new'],
  ]

  return (
    <AppStateContext>
      <BrowserRouter>
        <FullscreenLoaderDelay>
          <Switch>
            <Route path="/" exact>
              <NavbarCompact links={navbarLinks} />
              <MainPage />
            </Route>
            {/* <Redirect path="/" to="/feed" /> */}
            <Route path="/">
              <Navbar links={navbarLinks} />
              <Route path="/login">
                <LoginPage />
              </Route>
              <Route path="/register">
                <SignupPage />
              </Route>
              <Route path="/communities">
                <LoginCheck>
                  <CommunitiesPage />
                </LoginCheck>
              </Route>
              <Route path="/community/:id/edit">
                <LoginCheck>
                  <EditCommunityPage />
                </LoginCheck>
              </Route>
              <Route path="/community/:id">
                <CommunityPage />
              </Route>
              <Route path="/user/oauth2/callback/:provider">
                <LoginCheck>
                  <OAuth2ConnectionCallback />
                </LoginCheck>
              </Route>
              <Route path="/user">
                <LoginCheck>
                  <UserSettingsPage />
                </LoginCheck>
              </Route>
              <Route path="/new">
                <LoginCheck>
                  <CreateCommunityPage />
                </LoginCheck>
              </Route>
              <Route path="/qna">
                <QnAPage />
              </Route>
              <Route path="/starroad">
                <StarRoadPage />
              </Route>
              <Route path="/feed">
                <Feed />
              </Route>
              <Route path="/about">
                <AboutPage />
              </Route>
            </Route>
          </Switch>
        </FullscreenLoaderDelay>
      </BrowserRouter>
    </AppStateContext>
  )
}
