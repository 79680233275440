import React, { useState } from 'react'
import Box from '../../common/components/Box'
import styles from './RatingWindow.module.scss'
import star from './assets/rating-star.svg'
import starActive from './assets/rating-star-active.svg'
import { useAPI } from '../../common/features/api/provider'
import { useParams } from 'react-router-dom'
import { COMMUNITY_RATE_ROUTE } from '../../common/features/api/endpoints'

type RatingStarProps = {
  active: boolean
  setCurrent: () => void
  onClick: () => void
}

export function RatingStar({ active, setCurrent, onClick }: RatingStarProps) {
  const [hover, setHover] = useState(false)
  const onMouseEnter = () => {
    setHover(true)
    setCurrent()
  }
  const onMouseLeave = () => {
    setHover(false)
  }
  return (
    <img
      className={`${styles.ratingStar} ${
        active || hover ? styles.ratingStarActive : ''
      }`}
      src={active || hover ? starActive : star}
      alt="Звезда"
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  )
}

type RatingStarsRowProps = {
  amount: number
  onClick: (current: number) => void
}

export function RatingStars({ amount, onClick }: RatingStarsRowProps) {
  const [current, setCurrent] = useState<number>(0)
  return (
    <div className={styles.ratingStarsRow} onMouseLeave={() => setCurrent(0)}>
      {[...Array(amount)].map((_, idx) => (
        <RatingStar
          key={idx}
          active={current >= idx + 1}
          setCurrent={() => setCurrent(idx + 1)}
          onClick={() => onClick(current)}
        />
      ))}
    </div>
  )
}

export type RatingWindowProps = {
  rating: number
  marginTop?: number
  fluid?: boolean
  width?: number
  maxWidth?: number
}

// TODO: rerate validation
export function RatingWindow({
  rating,
  marginTop,
  maxWidth,
  width,
  fluid,
}: RatingWindowProps) {
  const api = useAPI()
  const { id } = useParams<{ id: string }>()
  const handleSubmit = async (v: number) => {
    if (api.options.token === '') {
      alert('Для выполнения этого действия вы должны авторизоваться')
    } else {
      await api.request<unknown>(
        'POST',
        `${COMMUNITY_RATE_ROUTE(id)}?rate=${v}`
      )
    }
  }
  return (
    <Box style={{ marginTop, width: width ?? (fluid ? '100%' : ''), maxWidth }}>
      <div className={styles.ratingWindow}>
        <h2 className={`text-bold ${styles.ratingWindowValue}`}>{rating}</h2>
        <RatingStars amount={5} onClick={handleSubmit} />
      </div>
    </Box>
  )
}
