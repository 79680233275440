import React, { ChangeEventHandler, PropsWithChildren } from 'react'
import { HTMLInputTypeAttribute } from 'react-router/node_modules/@types/react'
import { XOR } from '../utils/xor'
import styles from './InputOutlined.module.scss'

export type InputProps = PropsWithChildren<{
  onChange: (a: string) => any
  type?: HTMLInputTypeAttribute
  placeholder?: string
  value?: string
  fluid?: boolean
  marginTop?: string
  // TODO: bold
}>

export default function InputOutlined({ onChange, placeholder, value, type, fluid, marginTop }: InputProps) {
  placeholder = placeholder ?? 'Ввод'
  type = type ?? 'text'
  marginTop = marginTop ?? ''

  const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
    onChange(e.target.value)
  }

  return (
    <input type={type} onChange={handleChange} className={`${styles.inputOutlined} text-medium ${fluid ? styles.fluid : ''}`} style={{ marginTop }} placeholder={placeholder} value={value}/>
  )
}
