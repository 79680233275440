export enum UserPermissions {
  None = 0,
  ManageResources = 1 << 0,
  ManageUsers = 1 << 1,
  ManagePermissions = 1 << 2,
  ManageReferralLinks = 1 << 3,
  All = None |
    ManageResources |
    ManageUsers |
    ManagePermissions |
    ManageReferralLinks,
}
