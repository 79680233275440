import React, { LegacyRef, PropsWithChildren } from 'react'
import classNames from 'classnames'
import { Col, ColProps, Container, Hidden, Row, Visible } from 'react-grid-system'
import styles from './Footer.module.scss'
import Logo from './Logo'
import { PropsWithClassesAndStyle } from '../utils/style'
import { Link } from 'react-router-dom'

function FooterSection({ name, children, ...props }:
  PropsWithChildren<{ name: string; }> &
  Omit<ColProps, 'ref' /* for some reason react just doesn't like this prop here */>
) {
  return <div className={styles.footerSection}>
    <h2 className="text-md font-bold">{name}</h2>
    <ul className={styles.footerSectionLinks}>
      {children}
    </ul>
  </div>
}

export function FooterLink({ to, href, children, ...props }: PropsWithClassesAndStyle<PropsWithChildren<Partial<{ to: string } & { href: string }>>>) {
  return to ? <Link {...props} to={to} className={styles.footerLink}>
    {children}
  </Link> : <a {...props} href={href ?? '#'} className={styles.footerLink}>
    {children}
  </a>
}

export default function Footer({ compact }: { compact?: boolean }) {
  return <div className={styles.footer}>
    <Container>
      {!compact && <><Row justify='between' className={styles.footerRow}>
        <Col xs={12} md={4} lg={4}><h1 className="text-xl font-black">Helping you search</h1></Col>
        <Col offset={{ md: 2 }} className={styles.footerSections}>
          <FooterSection name="Company" offset={{ lg: 2 }} key="company">
            <FooterLink to="/about">About us</FooterLink>
            <FooterLink to="/starroad">Developers</FooterLink>
          </FooterSection>
          <FooterSection name="Resources" key="resources">
            <FooterLink href="https://blog.hyperia.space">Blog</FooterLink>
            <FooterLink to="/qna">QnA</FooterLink>
            <FooterLink href="https://docs.hyperia.space">API Documentation</FooterLink>
          </FooterSection>
          <FooterSection name="Documents" key="documents">
            <FooterLink to="#">Terms of Service</FooterLink>
            <FooterLink to="#">Privacy Policy</FooterLink>
            <FooterLink to="#">Licenses</FooterLink>
          </FooterSection>

        </Col>
      </Row>
        <Row className={styles.footerRow}>
          <Col xs={12} md={12}><hr className={styles.footerRowSeparator} /></Col>
        </Row>
      </>}
      <Row className={styles.footerRow} style={{ marginTop: !compact ? 24 : 0 }} justify='between' align="stretch">
        <Col xs={4} sm={6} className={styles.footerBrandRowLogoWrapper} >
          <Logo variant="alternate" style={{ height: 38 }} />
          <p className={classNames({ [styles.footerBrandSloganWrapper]: true }, 'text-md')}>
            <b>Hyperia{/* . */}</b>
            <Hidden xs sm><span style={{ color: '#808080' }}>Looking into the future</span></Hidden>
          </p>
        </Col>
        <Col xs={4} sm={5} md={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>In business since 2019</Col>
      </Row>
    </Container>
  </div >
}
