import React from 'react'
import styles from './UserSettingsPage.module.scss'
import { UserSettingsWindow } from './components/UserSettingsWindow'
import { Container, Row } from 'react-grid-system'

export default function UserSettingsPage() {
  return (
    <div className={`${styles.userSettingsWindowWrapper} fill-height`}>
      <UserSettingsWindow />
    </div>
  )
}
