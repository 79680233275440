export default function splash() {
  console.log(
    '%c Hyperia frontend ',
    'color: #00FA00;font-size: 50px;background-color: black;width: 100%;'
  )
  console.log(
    '%cHello fellow user, what are you doing here?',
    'color: #CF3A3A;font-size: 20px;font-weight: bold;'
  )
}
