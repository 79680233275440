import React, { PropsWithChildren, useContext } from 'react'
import {
  ErrorCode,
  Request,
  Requester,
  RequesterOptions,
  Response,
  RESTError,
} from './requester'
import { useAuthState } from '../auth/state/provider'
import axios, { AxiosError } from 'axios'
import { REFRESH_TOKENS_ROUTE } from '.'
import { AuthState } from '../auth/state/state'
import { setPair, logout } from '../auth/state/actions'
const APIContext = React.createContext<Requester>(new Requester({}))

export const APIProvider = ({ children }: PropsWithChildren<any>) => {
  const [tokens, dispatch] = useAuthState()
  const requester = new Requester(
    {
      token: tokens.accessToken,
      retryCount: 2,
    },
    {
      onError: async (
        requester: Requester,
        request: Request,
        response: Response<RESTError>
      ): Promise<boolean> => {
        if (response.data.code !== ErrorCode.INVALID_AUTHORIZATION_TOKEN) {
          return true
        }

        try {
          const newTokens = (
            await axios.put(REFRESH_TOKENS_ROUTE, JSON.stringify(tokens), {
              headers: {
                'Content-Type': 'application/json',
              },
            })
          ).data as AuthState
          requester.options.token = newTokens.accessToken
          dispatch(setPair(newTokens.accessToken, newTokens.refreshToken))
        } catch (err) {
          if (
            axios.isAxiosError(err) &&
            err.response &&
            err.response.data.code !== undefined
          ) {
            const { code, message, details } = err.response.data as RESTError
            if (code === ErrorCode.INVALID_TOKEN_PAIR) {
              dispatch(logout())
              requester.options.token = undefined
              return false
            }
            throw new RESTError(code, message, details)
          }
          throw err
        }

        return false
      },
    }
  )

  return <APIContext.Provider value={requester}>{children}</APIContext.Provider>
}

export const useAPI = () => useContext(APIContext)
