export type AuthState = {
  accessToken: string
  refreshToken: string
}

export const authLocalstorageKeys = {
  accessToken: 'hyperia:auth/access',
  refreshToken: 'hyperia:auth/refresh',
}

export function saveAuthState(state: AuthState) {
  localStorage.setItem(authLocalstorageKeys.accessToken, state.accessToken)
  localStorage.setItem(authLocalstorageKeys.refreshToken, state.refreshToken)
}
export function resetAuthState() {
  localStorage.removeItem(authLocalstorageKeys.accessToken)
  localStorage.removeItem(authLocalstorageKeys.refreshToken)
}

export const defaultAuthState: AuthState = {
  accessToken:
    window.localStorage.getItem(authLocalstorageKeys.accessToken) ?? '',
  refreshToken:
    window.localStorage.getItem(authLocalstorageKeys.refreshToken) ?? '',
}
