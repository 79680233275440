export enum ConnectionProvider {
  Discord = 'discord',
}
export const OAuth2ConnectionProviders = [ConnectionProvider.Discord]
export type OAuth2ConnectionProvider = typeof OAuth2ConnectionProviders[number]

export type OAuth2Token = {
  accessToken: string
  refreshToken: string
  tokenType: string
  expiry: Date
}

export type Connection = Partial<{
  oauth2: OAuth2Token
}>

export type Connections = {
  connections?: Record<ConnectionProvider, Connection | undefined>
}
