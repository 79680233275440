import React, { PropsWithChildren, TextareaHTMLAttributes } from 'react'
import styles from './TextArea.module.scss'

type TextAreaResize = 'vertical' | 'horizontal' | 'none'
const textAreaResizeClasses: Record<TextAreaResize, string> = {
  vertical: styles.textAreaResizeVertical,
  horizontal: styles.textAreaResizeHorizontal,
  none: styles.textAreaResizeNone,
}

type TextAreaProps = { onChange: (v: string) => void } & Partial<{
  cols: number
  rows: number
  fluid: boolean
  resize: TextAreaResize
  value: string
}>

export function TextArea({
  onChange,
  cols,
  rows,
  fluid,
  resize,
  value,
}: TextAreaProps) {
  fluid = fluid ?? false

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value)
  }
  return (
    <textarea
      cols={cols}
      rows={rows}
      value={value}
      className={`${styles.textArea} ${fluid ? styles.fluid : ''} ${
        resize ? textAreaResizeClasses[resize] : ''
      }`}
      onChange={handleChange}
    />
  )
}
