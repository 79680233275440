import React, { useEffect, useState } from 'react'
import Banner from '../common/components/Banner'
import { Container, Row, Col } from 'react-grid-system'
import CommunitiesWindow, {
  CommunityInWindowProps,
  CommunityWindowCommunityPartial,
} from './components/CommunitiesWindow'
import { useHistory } from 'react-router-dom'
import {
  COMMUNITY_ROUTE,
  OWNED_COMMUNITIES_ROUTE,
} from '../common/features/api'
import { useAPI } from '../common/features/api/provider'
import Footer from '../common/components/Footer'

export default function CommunitiesPage() {
  const [communities, setCommunities] = useState<
    CommunityWindowCommunityPartial[]
  >()
  const history = useHistory()
  const api = useAPI()
  const fetchCommunities = async () => {
    const communities = (
      await api.request<string[]>('GET', OWNED_COMMUNITIES_ROUTE('@me'))
    ).data
    const fetchedCommunities: CommunityWindowCommunityPartial[] = []
    for (const community of communities) {
      fetchedCommunities.push(
        (
          await api.request<CommunityWindowCommunityPartial>(
            'GET',
            COMMUNITY_ROUTE(community)
          )
        ).data
      )
    }
    setCommunities(fetchedCommunities)
  }
  useEffect(() => {
    fetchCommunities()
  }, [])
  return (
    <>
      <Banner
        title="Сообщества"
        content="Здесь собраны ваши сообщества, которые вы добавили на Hyperia"
      />
      <Container>
        <Row>
          <Col md={8} xl={6} offset={{ md: 2, xl: 3 }}>
            <CommunitiesWindow communities={communities ?? []} />
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  )
}
