import React, { useReducer, useState } from 'react'
import Button, { ButtonProps } from './Button'
import { LoadingAnimation } from './LoadingAnimation'

export type SaveButtonStyle = Partial<{
  color: string
  content: string | React.ReactNode
  disabled: boolean
}>

type SaveButtonAction = 'pending' | 'success' | 'fail' | 'reset'

export type SaveButtonProps = ButtonProps & {
  onClick: () => Promise<any>
  notificationTimeout?: number
}

export default function SaveButton({
  onClick,
  color,
  disabled,
  children,
  notificationTimeout = 3000,
  ...props
}: SaveButtonProps) {
  const [
    {
      color: colorOverride,
      disabled: disabledOverride,
      content: contentOverride,
    },
    dispatch,
  ] = useReducer(
    (state: SaveButtonStyle, action: SaveButtonAction): SaveButtonStyle => {
      switch (action) {
        case 'pending':
          return {
            ...state,
            content: <LoadingAnimation duration={1000} />,
            disabled: true,
          }
        case 'success':
          return {
            color: 'status-green',
            content: 'Сохранено!',
            disabled: true,
          }
        case 'fail':
          return { color: 'status-red', content: 'Ошибка!', disabled: true }
      }

      return {}
    },
    {}
  )

  const handleClick = async () => {
    dispatch('pending')
    try {
      await onClick()
      dispatch('success')
    } catch (e) {
      dispatch('fail')
      throw e
    } finally {
      setTimeout(() => dispatch('reset'), notificationTimeout)
    }
  }

  return (
    <Button
      color={colorOverride ?? color}
      onClick={handleClick}
      disabled={disabledOverride ?? disabled}
      disabledFocus
      {...props}
    >
      {contentOverride ?? children}
    </Button>
  )
}
