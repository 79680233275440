/* eslint-disable camelcase */

import { TagTypes } from './tags'

export enum CommunityPlatform {
  Discord = 1,
}
export const CommunityPlatforms = {
  discord: CommunityPlatform.Discord,
}

export type NewCommunityData = {
  name: string
  desc: string
  kind: CommunityPlatform
  avatar_url?: string
  link: string
  tags: string[]
}

export type Flag = 'ru'

export type Community = {
  id: string
  name: string
  desc: string
  link: string
  avatar_url: string
  kind: keyof typeof CommunityPlatforms
  tags: TagTypes[]
  badges: number
  owner: string
  average_rate: number
  preferred_language: Flag
}

export type SearchCommunity = {
  avatarUrl: string
} & Omit<Community, 'avatar_url' | 'tags' | 'badges' | 'owner' | 'average_rate'>
