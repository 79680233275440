import React from 'react'
import styles from './SignupPage.module.scss'
import SignupWindow from './components/SignupWindow'
import { LoginCheck } from '../common/components/LoginCheck'

export default function SignupPage() {
  return (
    <div className={`${styles.signupWindowWrapper} fill-height`}>
      <SignupWindow />
    </div>
  )
}
