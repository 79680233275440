import Box from '../../common/components/Box'
import React, { PropsWithChildren } from 'react'
import styles from './CommunitiesWindow.module.scss'
import { useHistory } from 'react-router-dom'
import { useAPI } from '../../common/features/api/provider'
import { resolveIcon } from '../../common/features/communities/avatar'

export type CommunityInWindowProps = PropsWithChildren<
  {
    onClick: () => any
  } & Omit<CommunityWindowCommunityPartial, 'id'>
>

export function CommunityInWindow({
  onClick,
  avatar_url: avatarUrl,
  name,
}: CommunityInWindowProps) {
  return (
    <div className={styles.communityInWindow} onClick={() => onClick()}>
      <img
        src={resolveIcon({ name, avatar_url: avatarUrl })}
        alt={name}
        className={styles.communityInWindowAvatar}
      />
      <p>{name}</p>
    </div>
  )
}

export type CommunityWindowCommunityPartial = {
  // eslint-disable-next-line camelcase
  avatar_url: string
  name: string
  id: string
}

export type CommunityWindowProps = React.PropsWithChildren<{
  communities: CommunityWindowCommunityPartial[]
}>

export default function CommunitiesWindow({
  communities,
}: CommunityWindowProps) {
  const history = useHistory()

  return (
    <Box style={{ position: 'relative', top: '-96px' }}>
      <h2 style={{ textAlign: 'center' }} className={styles.communityTitle}>
        Ваши сообщества
      </h2>
      <div className={styles.communitiesWrapper}>
        {communities.map(({ id, avatar_url: avatarUrl, name }) => (
          <CommunityInWindow
            key={id}
            avatar_url={avatarUrl}
            name={name}
            onClick={() => history.push(`/community/${id}`)}
          />
        ))}
      </div>
    </Box>
  )
}
