import React, { useEffect, useState } from 'react'
import styles from './OAuth2Connection.module.scss'
import { useAPI } from '../../common/features/api/provider'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
  CONNECTION_AUTHENTICATION_ROUTE,
  CONNECTION_ROUTE,
  USER_ROUTE,
} from '../../common/features/api'
import {
  User,
  OAuth2ConnectionProvider,
  OAuth2ConnectionProviders,
} from '../../common/features/users'
import querystring from 'query-string'
import capitalize from '../../common/utils/capitalize'

export function OAuth2ConnectionCallback() {
  const history = useHistory()
  const location = useLocation()
  const params = useParams<{ provider: OAuth2ConnectionProvider }>()

  const api = useAPI()
  const callback = async () => {
    const { code, state } = querystring.parse(location.search)
    if (!code || !state) {
      history.push('/user')
      return
    }
    const { id } = (await api.request<User>('GET', USER_ROUTE('@me'))).data // TODO: @me for auth & connections
    await api.request<unknown>('POST', CONNECTION_ROUTE(id, params.provider), {
      json: {
        oauth2Callback: {
          code,
          state,
        },
      },
    })
    history.push('/user')
  }
  useEffect(() => {
    if (!OAuth2ConnectionProviders.includes(params.provider)) {
      history.push('/user')
    } else {
      callback()
    }
  }, [])
  return <></>
}

type ButtonProps = {
  provider: OAuth2ConnectionProvider
  logo: string
  setIdentity: (identity?: string) => void
  identity?: string
}

export function ConnectionButton({
  provider,
  logo,
  setIdentity,
  identity,
}: ButtonProps) {
  const api = useAPI()
  const redirect = async () => {
    const me = (await api.request<User>('GET', USER_ROUTE('@me'))).data
    const url = (
      await api.request<{ oauth2RedirectUrl: string }>(
        'GET',
        CONNECTION_AUTHENTICATION_ROUTE(me.id, provider)
      )
    ).data.oauth2RedirectUrl
    window.location.href = url
  }
  const disconnect = async () => {
    if (
      window.confirm(`Вы хотите отсоединить ${capitalize(provider)} аккаунт?`)
    ) {
      const me = (await api.request<User>('GET', USER_ROUTE('@me'))).data
      await api.request<unknown>('DELETE', CONNECTION_ROUTE(me.id, provider))
      setIdentity(undefined)
    }
  }
  return (
    <button
      className={styles.discordButton}
      onClick={() => (identity ? disconnect() : redirect())}
    >
      <img src={logo} alt={`Лого ${provider}`} />
      {identity ?? 'Подключить'}
    </button>
  )
}
