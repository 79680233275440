import React, { useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import SearchBox from '../common/components/SearchBox'
import { authLocalstorageKeys } from '../common/features/auth/state/state'
import { Navbar } from './components/Navbar'

export function MainPage() {
  const [query, setQuery] = useState<string>('')
  const history = useHistory()

  if (localStorage.getItem(authLocalstorageKeys.accessToken)) {
    return <Redirect to="/feed" />
  }

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        position: 'absolute',
        top: '50%',
        padding: 24,
        alignItems: 'center',
        flexDirection: 'column',
        height: '50%',
        justifyContent: 'space-between',
      }}
    >
      {/* <Navbar /> */}
      <div
        style={{
          position: 'relative',
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <h1
          className="text-xl text-bold"
          style={{
            position: 'absolute',
            top: -(56 + 48),
            left: '50%',
            transform: 'translate(-50%)',
          }}
        >
          Hyperia
        </h1>
        <SearchBox
          setQuery={setQuery}
          onSubmit={() => query.length && history.push(`/feed?q=${query}`)}
          style={{ width: 734, minWidth: 100 }}
        />
      </div>
      <p>Русский | English</p>
    </div>
  )
}
