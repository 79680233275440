import grid from './grid'
import splash from './splash'

function load(...a: (() => any)[]) {
  for(const f of a) {
    f()
  }
}

export default function() {
  load(grid, splash)
}
