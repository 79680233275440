import React, { PropsWithChildren, useState } from 'react'
import imageButtonCss from './ImageButton.module.scss'
import uploadIcon from '../assets/avatar-upload.svg'
import { PropsWithClassesAndStyle } from '../utils/style'
import classNames from 'classnames'

type ImageButtonBaseProps = PropsWithClassesAndStyle<PropsWithChildren<
  { image: string } & Partial<{
    alt: string
    onClick: () => any
    onFileUploaded: (f: File) => void
    fill: boolean
    width: number | string
    height: number | string
    imageStyle: React.CSSProperties
    imageClassName: string
    accept?: string[]
    uploadable: boolean
  }>
>>

export type ImageButtonProps = {
  onClick: ImageButtonBaseProps['onClick']
} & Omit<ImageButtonBaseProps, 'onFileUploaded' | 'uploadable' | 'accept'>
export type UploadableButtonProps = {
  onFileUploaded: ImageButtonBaseProps['onFileUploaded']
} & Omit<ImageButtonBaseProps, 'onClick' | 'uploadable'>

function ImageButtonBase({
  image,
  onClick,
  onFileUploaded,
  alt,
  fill,
  width,
  height,
  style,
  imageStyle,
  accept,
  uploadable,
  className,
  imageClassName,
}: ImageButtonBaseProps) {
  // console.log(imageButtonCss)
  const fileRef = React.createRef<HTMLInputElement>()
  const [onHover, setOnHover] = useState<boolean>(false)

  const handleFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onFileUploaded && event.target.files) {
      onFileUploaded(event.target.files[0])
      if (fileRef.current) fileRef.current.value = ''
    }
  }

  const handleClick = () => {
    if (uploadable) fileRef.current?.click()
    else if (onClick) onClick()
  }

  return (
    <button
      onClick={handleClick}
      className={
        classNames(imageButtonCss.imageButton +
          ` ${fill === true ? imageButtonCss.fill : ''} ${uploadable ? imageButtonCss.imageButtonUploadable : ''
          }`, className)
      }
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
      style={style}
    >
      {uploadable && (
        <img
          src={uploadIcon}
          alt="Загрузить"
          className={imageButtonCss.imageButtonUploadIcon}
          style={{ display: onHover ? 'block' : 'none' }}
        />
      )}
      <img
        src={image}
        alt={alt}
        width={width}
        height={height}
        style={imageStyle}
        className={imageClassName}
      />
      {uploadable && (
        <input
          type="file"
          ref={fileRef}
          style={{ display: 'none' }}
          accept={accept?.join(' ')}
          onChange={handleFile}
        />
      )}
    </button>
  )
}

export default function ImageButton(props: ImageButtonProps) {
  return ImageButtonBase(props)
}
export function UploadableImageButton(props: UploadableButtonProps) {
  return ImageButtonBase({ ...props, uploadable: true })
}
