import React, { ChangeEventHandler, PropsWithChildren, useEffect } from 'react'
import { HTMLInputTypeAttribute } from 'react-router/node_modules/@types/react'
import styles from './Input.module.scss'

export type InputProps = PropsWithChildren<{
  onChange: (a: string) => any
  type?: HTMLInputTypeAttribute
  placeholder?: string
  value?: string
  fluid?: boolean
  marginTop?: string
  valid?: boolean
}>

export default function Input({
  onChange,
  type,
  placeholder,
  value,
  fluid,
  marginTop,
  valid,
}: InputProps) {
  // TODO: apply InputOutline fixes
  valid = valid ?? true
  placeholder = placeholder ?? 'Ввод'
  type = type ?? 'text'
  marginTop = marginTop ?? ''

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange(e.target.value)
  }
  return (
    <input
      type={type}
      onChange={handleChange}
      placeholder={placeholder}
      value={value}
      className={`${styles.input} text-medium ${fluid ? styles.fluid : ''} ${
        !valid ? styles.invalid : ''
      }`}
      style={{ marginTop }}
    />
  )
}
