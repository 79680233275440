import React, { PropsWithChildren, useMemo, useState } from 'react'
import styles from './Tag.module.scss'

type TagProps = {
  onChange?: (v: boolean) => void
  enabled?: boolean
}

export function Tag({
  children,
  onChange,
  enabled,
}: PropsWithChildren<TagProps>) {
  const classList = useMemo(() => {
    return `${styles.tag} ${enabled ? styles.tagEnabled : ''}`
  }, [enabled])

  return (
    <button
      className={classList}
      onClick={() => onChange && onChange(!enabled)}
    >
      {children}
    </button>
  )
}
