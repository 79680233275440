import React, { PropsWithChildren } from 'react'
import Banner from '../common/components/Banner'
import wumpus from '../common/assets/wumpus.svg'
import { Col, Container, Row } from 'react-grid-system'
import { StarRoadCard, StarRoadCardProps } from './components/StarRoadCard'
import styles from './StarRoadPage.module.scss'
import Footer from '../common/components/Footer'

const users = {
  team: [
    {
      nick: 'Vlfz',
      desc: 'Со-основатель проекта',
      publicURL: 'https://boticord.top',
      avatarURL: 'https://avatars.githubusercontent.com/u/32297717?v=4',
    },
    {
      nick: 'Lapfed255',
      desc: 'Основатель проекта. Фронтенд и бекенд разработка',
      publicURL: 'https://github.com/FedorLap2006',
      avatarURL:
        'https://cdn.discordapp.com/avatars/553557567591284737/ec6e28cb12cc3105e49147354406316d.png',
    },
    {
      nick: 'Mrsasha45op',
      desc: 'Со-основатель проекта',
      publicURL: 'https://github.com/Mrsasha45op',
      avatarURL:
        'https://cdn.discordapp.com/avatars/330766293856813056/1250aedbfaf5ecdc5a1d3b1fd5c6f507.png',
    },
    {
      nick: 'KittenLord',
      desc: 'Модерация вебсайта и Discord сервера проекта',
      avatarURL:
        'https://api.hyperia.space/cdn/v1/avatars/08d633aa-c6f4-4a94-ab3b-5879da64d865',
    },
    {
      nick: 'TheKingOfTime',
      desc: 'Модерация Discord сервера проекта',
      publicURL: 'https://github.com/TheKing-OfTime',
      avatarURL: 'https://avatars.githubusercontent.com/u/68960526?v=4',
    },
  ] as StarRoadCardProps[],

  specialThanks: [
    {
      nick: 'KislBall',
      desc: 'Фронтенд разработка',
      publicURL: 'https://github.com/kislball',
      avatarURL: 'https://avatars.githubusercontent.com/u/47415631?v=4',
    },
    {
      nick: 'Debug_pro',
      desc: 'Фронтенд разработка',
      publicURL: 'https://github.com/Debug-pro-dev',
      avatarURL: 'https://avatars.githubusercontent.com/u/63473508?v=4',
    },
    {
      nick: 'Иван Васильевич',
      desc: 'Управление проектом и системная администрация',
      avatarURL:
        'https://cdn.discordapp.com/avatars/439816046745092096/1372acb1a954571d34ae91231c5bb9cf.png',
    },
    {
      nick: 'Zont1k',
      desc: 'Денежная помощь проекту',
      publicURL: 'https://github.com/AlxelZot',
      avatarURL: 'https://avatars.githubusercontent.com/u/82260790?v=4',
    },
    {
      nick: 'SunRise',
      desc: 'Пентестинг и выявление уязвимостей',
      publicURL: 'https://github.com/Michael1821',
      avatarURL:
        'https://cdn.discordapp.com/avatars/428464195458170881/a5f5be0dae4cf7b95d88b688adb8d6eb.png',
    },
  ] as StarRoadCardProps[],
}

export function StarBoardSection({
  name,
  children,
}: PropsWithChildren<{ name: string }>) {
  return (
    <div className={styles.starRoadSection}>
      <h1
        style={{ textAlign: 'center' }}
        className={`text-xl ${styles.starRoadSectionHeader}`}
      >
        {name}
      </h1>
      <Row style={{ rowGap: 24 }}>{children}</Row>
    </div>
  )
}

export function StarRoadPage() {
  return (
    <>
      <Banner
        title="Star Road"
        image={wumpus}
        content="Те люди, которые участвовали в проекте, помогали ему, и были бета-тестерами"
      />
      <Container style={{ padding: '32px 0' }}>
        <StarBoardSection name="Команда">
          {users.team.map((u) => (
            <Col xl={4} key={u.nick}>
              <StarRoadCard {...u} />
            </Col>
          ))}
        </StarBoardSection>
        <StarBoardSection name="Особая благодарность">
          {users.specialThanks.map((u) => (
            <Col xl={4} key={u.nick}>
              <StarRoadCard {...u} />
            </Col>
          ))}
        </StarBoardSection>
      </Container>
      <Footer />
    </>
  )
}
