import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './root.scss'
import plugins from './common/plugins'

plugins()

function Root() {
  return <App />
}

ReactDOM.render(<Root />, document.getElementById('root'))
