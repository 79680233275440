import React, { PropsWithChildren } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '../../common/components/Button'
import { Tag } from '../../common/components/Tag'
import { BadgeIcons, BadgeType } from '../../common/features/communities/badges'
import { Flag } from '../../common/features/communities/community'
import { Tags, TagTypes } from '../../common/features/communities/tags'
import useFlag from '../../common/hooks/useFlag'
import styles from './ControlPanel.module.scss'

type ControlPanelProps = {
  id: string
  tags: TagTypes[]
  badges: BadgeType[]
  owner?: boolean
  language: Flag
}

export function Field({
  name,
  children,
  contentStyles,
  style,
}: PropsWithChildren<{
  name: string
  contentStyles?: React.CSSProperties
  style?: React.CSSProperties
}>) {
  return (
    <span className={styles.communityControlPanelField} style={style}>
      <p
        className={`text-bold text-md ${styles.communityControlPanelFieldTitle}`}
      >
        {name}
      </p>
      <span
        className={styles.communityControlPanelFieldContent}
        style={contentStyles}
      >
        {children}
      </span>
    </span>
  )
}

export function ControlPanel({
  id,
  owner,
  tags,
  badges,
  language,
}: ControlPanelProps) {
  owner = owner ?? false

  const history = useHistory()
  const flagImg = useFlag(language)

  return (
    <div className={styles.communityControlPanel}>
      {owner && (
        <Field name="Управление:">
          <div className={styles.controlPanelOwnerButtonsWrapper}>
            <Button
              color="brand"
              onClick={() => history.push(`/community/${id}/edit`)}
              className={styles.communityControlPanelOwnerButton}
            >
              Редактировать
            </Button>
            <Button
              /* eslint-disable-next-line @typescript-eslint/no-empty-function */
              onClick={() => {}}
              color="brand"
              className={styles.communityControlPanelOwnerButton}
            >
              Обновить статистику
            </Button>
          </div>
        </Field>
      )}
      {tags.length !== 0 && (
        <Field name="Теги:">
          <div className={styles.controlPanelTags}>
            {tags.map((e) => (
              <Tag key={e}>{Tags[e]}</Tag>
            ))}
          </div>
        </Field>
      )}
      {badges.length !== 0 && (
        <Field name="Значки:">
          <div className={styles.controlPanelBadges}>
            {badges.map((e) => (
              <img
                src={BadgeIcons[e]}
                className={styles.controlPanelBadge}
                alt="Значок"
              />
            ))}
          </div>
        </Field>
      )}
      <Field name="Язык:">
        <img
          src={flagImg}
          alt={language}
          height={20}
          width={30}
          className="shadow semi-heavy"
        />
      </Field>
    </div>
  )
}
