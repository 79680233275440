import { AuthActionTypes, RESET_ACTION, SET_PAIR_ACTION } from './types'
import { Action } from '../../defs'

export function setPair(
  accessToken: string,
  refreshToken: string
): Action<AuthActionTypes> {
  return {
    type: SET_PAIR_ACTION,
    value: { accessToken, refreshToken },
  }
}

export function logout(): Action<AuthActionTypes> {
  return {
    type: RESET_ACTION,
    value: {},
  }
}
