import React, { PropsWithChildren, useState, useMemo, useEffect } from 'react'
import { SearchTypes } from '../features/communities/search'
import styles from './SearchBox.module.scss'
import Input from './Input'
import Button from './Button'
import lens from '../assets/lens.svg'
import settings from '../assets/settings.svg'
import Box from './Box'
import { Tag } from './Tag'
import { Tags, TagTypes } from '../features/communities'
import { PropsWithStyle } from '../utils/style'

export type SearchBoxFiltersData = {
  tags: TagTypes[]
}
type FiltersProps = {
  onApply: (f: SearchBoxFiltersData) => void
  onClear: () => void
}

export type SearchBoxProps = PropsWithStyle<
  PropsWithChildren<{
    query?: string
    setQuery: (a: string) => any
    setSearchType?: (a: SearchTypes) => any
    onSubmit: () => void
    onFiltersApply?: (f: SearchBoxFiltersData) => void
    onFiltersClear?: () => void
    doUpper?: boolean
  }>
>

function Filter({ label, children }: PropsWithChildren<{ label: string }>) {
  return (
    <div className={styles.filtersMenuItem} key={`filter-${label}`}>
      <p className={`text-sm text-bold ${styles.filtersMenuItemLabel}`}>
        {label}
      </p>
      {children}
    </div>
  )
}

function Filters({ onApply, onClear }: FiltersProps) {
  const [opened, setOpened] = useState(false)
  const menuRef = React.createRef<HTMLDivElement>()
  const [menuHeight, setMenuHeight] = useState(0)
  const [tags, setTags] = useState<Record<string, boolean>>({})

  useEffect(() => {
    if (menuRef.current) setMenuHeight(menuRef.current.scrollHeight)
  }, [opened, menuRef])

  const setTag = (n: string, s: boolean) => {
    setTags({ ...tags, [n]: s })
  }
  const handleClear = () => {
    setTags({})
    onClear()
  }

  return (
    <>
      <button
        key="filter-button"
        className={styles.filtersMenuButton}
        // style={{ borderBottom: opened ? '1px solid #99aab5' : 'none' }}
        onClick={() => setOpened(!opened)}
      >
        <img src={settings} alt="Фильтры" />
        <p className={`text-sm text-bold ${styles.filtersMenuButtonText}`}>
          Фильтры
        </p>
      </button>
      <div
        key="filter-menu"
        ref={menuRef}
        className={styles.filtersMenu}
        style={{ height: opened ? menuHeight : 0 }}
      >
        <Filter label={'Теги'}>
          <div className={styles.searchBoxTagWrapper}>
            {Object.values(Tags).map((e) => (
              <Tag
                key={e}
                enabled={tags[e] ?? false}
                onChange={(state) => setTag(e, state)}
              >
                {e}
              </Tag>
            ))}
          </div>
        </Filter>
        <div className={styles.filtersMenuButtons}>
          <button
            className={`${styles.filtersMenuButtonsApply} text-bold text-sm`}
            onClick={() =>
              onApply({
                tags: Object.entries(tags)
                  .filter(([, v]) => v)
                  .map(
                    ([mapped]) =>
                      Object.entries(Tags).filter(
                        ([, value]) => value === mapped
                      )[0][0] as TagTypes
                  ),
              })
            }
          >
            Применить
          </button>

          <button
            className={`${styles.filtersMenuButtonsClear} text-bold text-sm`}
            onClick={handleClear}
          >
            Очистить
          </button>
          {/* <Button color="brand" styles={{ padding: '12px 24px' }}>
            Применить
          </Button>
          <Button color="status-red" styles={{ padding: '12px 24px' }}>
            Очистить
          </Button> */}
        </div>
      </div>
    </>
  )
}

export default function SearchBox({
  setQuery,
  query,
  setSearchType,
  onSubmit,
  onFiltersApply,
  onFiltersClear,
  style,
  doUpper,
}: SearchBoxProps) {
  return (
    <Box doUpper={doUpper} style={style}>
      <div className={styles.searchBoxHeader}>
        <Input
          onChange={setQuery}
          // placeholder={'Помогаем найти'}
          placeholder={'Помогаем искать'}
          value={query}
          fluid
        />
        <div className={styles.searchBoxButtonWrapper}>
          <Button borderRadius={20} onClick={() => onSubmit()}>
            <img src={lens} alt="Лупа" />
          </Button>
        </div>
      </div>
      {(onFiltersApply || onFiltersClear) && (
        <Filters onApply={onFiltersApply!} onClear={onFiltersClear!} />
      )}
    </Box>
  )
}
