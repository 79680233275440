import React from 'react'
import { PropsWithClassesAndStyle, PropsWithStyle } from '../utils/style'
import styles from './Skeleton.module.scss'

type SkeletonElementProps = PropsWithClassesAndStyle<{
  animate?: boolean
}>

export default function SkeletonElement({
  className,
  style,
  animate,
}: SkeletonElementProps) {
  return (
    <div
      className={`${animate ? 'animation-skeleton' : 'skeleton'} ${
        className ?? ''
      }`}
      style={{
        ...style,
      }}
    />
  )
}

type SkeletonArticleProps = PropsWithClassesAndStyle<{
  lines: number
  fontSize?: string | number
  lineMargin?: string | number
  elementProps?: SkeletonElementProps
}>

export function SkeletonArticle({
  className,
  style,
  lines,
  fontSize,
  lineMargin,
  elementProps,
}: SkeletonArticleProps) {
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      {[...Array(lines)].fill({}).map((_, idx) => (
        <SkeletonElement
          {...elementProps}
          style={{
            ...(elementProps?.style ?? {}),
            width: idx === lines - 1 ? '80%' : '100%',
            height: `calc(${fontSize ?? '1em'} * 0.7)`,
            marginTop: idx > 0 ? lineMargin ?? 5 : 0,
          }}
        />
      ))}
    </div>
  )
}
