import { useState } from 'react'

export default function useInput(): [string, (a: string) => void] {
  const [data, setData] = useState<string>()

  function handleChange(v: string) {
    setData(v)
  }

  return [data!, handleChange]
}
