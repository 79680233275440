import React from 'react'
import { PropsWithStyle } from '../utils/style'
import styles from './ErrorWrapper.module.scss'

type ErrorWrapperProps = React.PropsWithChildren<
  PropsWithStyle<{
    message?: string
    messagePadding?: number | string
    position?: 'before' | 'after'
  }>
>

export function ErrorWrapper({
  message,
  position,
  children,
  messagePadding,
  style,
}: ErrorWrapperProps) {
  if (!message) {
    return style ? <div style={style}>{children}</div> : <>{children}</>
  }
  const element = (
    <span
      className={`${styles.errorMessage} text-sm`}
      style={{ paddingLeft: messagePadding ?? 0 }}
    >
      {message}
    </span>
  )

  return (
    <div className={styles.errorWrapper} style={style}>
      {(!position || position === 'before') && element}
      {children}
      {position === 'after' && element}
    </div>
  )
}
