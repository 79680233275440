import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'
import buttonCss from './Button.module.scss'

type ButtonBaseProps = PropsWithChildren<{
  color?: string
  textColor?: string
  shadowColor?: string
  borderRadius?: number
  disabledFocus?: boolean
  styles?: React.CSSProperties
  className?: string

  onClick?: () => any
  disabled?: boolean
  href?: string
  external?: boolean
}>

export type ButtonProps = {
  onClick: () => any
} & Omit<ButtonBaseProps, 'href' | 'external'>
export type LinkButtonProps = {
  href: string
} & Omit<ButtonBaseProps, 'onClick'>

function ButtonBase({
  children,
  onClick,
  href,
  external,
  disabled,
  disabledFocus,
  color = 'brand',
  textColor = 'white',
  shadowColor,
  borderRadius = 50,
  styles,
  className,
}: ButtonBaseProps & Partial<ButtonProps & LinkButtonProps>) {
  shadowColor = shadowColor ?? color // for future

  const baseClasses = classNames(
    buttonCss[`${textColor}Button`],
    buttonCss[`${color}BgButton`],
    buttonCss.button,
    'text-medium',
    { [buttonCss.disabledFocusButton]: disabledFocus ?? false },
    className ?? ''
  )

  if (href) {
    const linkClasses = classNames(baseClasses, buttonCss.linkButton)
    return external ? (
      <a
        href={href}
        className={linkClasses}
        style={{ borderRadius, ...styles }}
      >
        {children}
      </a>
    ) : (
      <Link
        to={href}
        className={linkClasses}
        style={{ borderRadius, ...styles }}
      >
        {children}
      </Link>
    )
  }

  return (
    <button
      disabled={disabled}
      className={baseClasses}
      onClick={onClick}
      style={{ borderRadius, ...styles }}
    >
      {children}
    </button>
  )
}

export default function Button(props: ButtonProps) {
  return <ButtonBase {...props} />
}

export function LinkButton(props: LinkButtonProps) {
  return <ButtonBase {...props} />
}
