import React from 'react'
import l from '../assets/logo.svg'
import ld from '../assets/logo-dark.svg'
import la from '../assets/logo-alternate.svg'
import { PropsWithClassesAndStyle } from '../utils/style'

const variants = {
  dark: ld,
  light: l,
  alternate: la,
}

export default function Logo({ className, style, variant, dark }: PropsWithClassesAndStyle<{
  variant?: keyof typeof variants
  dark?: boolean // TODO: remove when compatiblity is not needed
}>) {
  return <img
    src={variants[variant ?? (dark ? 'dark' : 'light')]}
    alt="hyperia logo"
    className={className}
    style={style}
  />
}
