import { Community } from './community'

export const defaultBackgroundColor = '2C2F33'

export function resolveIcon({
  name,
  avatar_url,
}: Partial<Pick<Community, 'avatar_url' | 'name'>>): string {
  avatar_url = avatar_url ?? ''
  return avatar_url !== ''
    ? avatar_url
    : `https://avatars.dicebear.com/api/initials/${
        name ?? ''
      }.svg?background=%23${defaultBackgroundColor}`
}
