import dev from '../../assets/badges/dev.svg'
import fav from '../../assets/badges/star.svg'
import pioneer from '../../assets/badges/pioneer.svg'
import bughunter from '../../assets/badges/bug-hunter.svg'

export enum BadgeType {
  Favorite = 1 << 0,
  Pioneer = 1 << 1,
  Developer = 1 << 2,
  BugHunter = 1 << 3,
}
export const BadgeIcons: Record<BadgeType, string> = {
  [BadgeType.Favorite]: fav,
  [BadgeType.Pioneer]: pioneer,
  [BadgeType.Developer]: dev,
  [BadgeType.BugHunter]: bughunter,
}

export const BadgeNames: Record<BadgeType, string> = {
  [BadgeType.Favorite]: 'Выбор редакции',
  [BadgeType.Pioneer]: 'Первопроходец',
  [BadgeType.Developer]: 'Разработчик',
  [BadgeType.BugHunter]: 'Баг-хантер',
}

export const Badges: BadgeType[] = [
  BadgeType.Favorite,
  BadgeType.Pioneer,
  BadgeType.BugHunter,
  BadgeType.Developer,
]
