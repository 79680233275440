import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import { USER_ROUTE } from '../api'
import { useAPI } from '../api/provider'
import { User } from './user'
type UserValue = [
  User | undefined,
  React.Dispatch<React.SetStateAction<User | undefined>>
]

// eslint-disable-next-line @typescript-eslint/no-empty-function
const UserContext = React.createContext<UserValue>([undefined, (_) => {}])

export function UserProvider({ children }: PropsWithChildren<any>) {
  const [user, setUser] = useState<User>()
  const api = useAPI()
  const fetchUser = async () =>
    setUser((await api.request<User>('GET', USER_ROUTE('@me'))).data)
  useEffect(() => {
    if (api.options.token !== '') {
      fetchUser()
    }
  }, [])
  return (
    <UserContext.Provider value={[user, setUser]}>
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => useContext(UserContext)
