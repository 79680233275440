import React, {
  createContext,
  PropsWithChildren,
  useContext,
  Dispatch,
  useEffect,
} from 'react'
import { useAuthReducer } from './index'
import { AuthState } from './state'
import { Action } from '../../defs'
import { AuthActionTypes } from './types'

export type AuthContextType = [AuthState, Dispatch<Action<AuthActionTypes>>]
export const AuthContext = createContext<AuthContextType>(
  ([] as unknown) as [AuthState, Dispatch<Action<AuthActionTypes>>]
)

export const AuthProvider = ({ children }: PropsWithChildren<any>) => {
  const [state, dispatch] = useAuthReducer()

  return (
    <AuthContext.Provider value={[state, dispatch]}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthState = () => useContext(AuthContext)
