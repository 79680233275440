import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { Select } from './SelectWrapper'
import Box from './Box'
import InputOutlined from './InputOutlined'
import styles from './CommunityInfoForm.module.scss'

import discordLogo from '../assets/logos/discord-color.svg'
import ru from '../assets/flags/ru.png'
import { TextArea } from './TextArea'
import Button from './Button'
import useInput from '../hooks/useInput'
import {
  CommunityPlatform,
  Community,
  CommunityPlatforms,
  TagTypes,
  Flag,
} from '../features/communities'
import {
  COMMUNITY_ICON_ROUTE,
  COMMUNITY_ROUTE,
  NEW_COMMUNITY_ROUTE,
} from '../features/api'
import { useAPI } from '../features/api/provider'
import { UploadableImageButton } from './ImageButton'
import { useHistory } from 'react-router-dom'
import { TagSelect } from './TagSelect'
import SaveButton from './SaveButton'

type FieldProps = {
  name: string
  required?: boolean
  description?: string
  component: React.ReactElement
}

const platforms = [
  { label: 'Discord', value: CommunityPlatform.Discord, image: discordLogo },
]

const languages = [{ label: 'Русский', value: 'ru' as Flag, image: ru }]

function Field({ name, required, description, component }: FieldProps) {
  required = required ?? false
  return (
    <Container
      fluid
      className={styles.communityInfoFormFieldWrapper}
      style={{ padding: 0 }}
    >
      <Row justify="between">
        <Col className={styles.communityInfoFormFieldInfo} md={12} lg={4}>
          <h2 className="text-bold text-md">
            {name}
            {required && (
              <span
                className={`text-md ${styles.communityInfoFormFieldRequired}`}
              >
                *
              </span>
            )}
          </h2>
          {description && (
            <p
              className={`text-sm ${styles.communityInfoFormFieldDescription}`}
            >
              {description}
            </p>
          )}
        </Col>
        <Col
          sm={7}
          md={12}
          lg={7}
          className={styles.communityInfoFormFieldValue}
        >
          {component}
        </Col>
      </Row>
    </Container>
  )
}

type CommunityInfoFormProps = {
  edit: boolean
  id: string
}

function CommunityInfoFormBase({ edit, id }: Partial<CommunityInfoFormProps>) {
  const [name, setName] = useInput()
  const [desc, setDesc] = useInput()
  const [tags, setTags] = useState<TagTypes[]>([])
  const [link, setLink] = useInput()
  const [avatarUrl, setAvatarUrl] = useState<string>()
  const [avatarUrlInput, setAvatarUrlInput] = useInput()
  const [avatarFile, setAvatarFile] = useState<File>()
  const [platform, setPlatform] = useState<CommunityPlatform>()
  const [language, setLanguage] = useState<Flag>()
  const api = useAPI()
  const history = useHistory()

  const fetchData = async () => {
    const data = (
      await api.request<Community>('GET', COMMUNITY_ROUTE(id as string))
    ).data
    setName(data.name)
    setDesc(data.desc)
    setLink(data.link)
    setTags(data.tags ?? [])
    setAvatarUrl(data.avatar_url)
    setPlatform(CommunityPlatforms[data.kind])
    setLanguage(data.preferred_language)
  }

  const handleCreate = async () => {
    const { id } = (
      await api.request<{ id: string }>('POST', NEW_COMMUNITY_ROUTE, {
        json: {
          name,
          desc,
          kind: platform,
          link,
          avatar_url: avatarFile ? undefined : avatarUrl,
          tags,
          preferred_language: language,
        },
      })
    ).data
    if (avatarFile) {
      const data = new FormData()
      data.append('file', avatarFile, avatarFile.name)
      await api.request<{}>('POST', COMMUNITY_ICON_ROUTE(id), {
        formData: data,
      })
      await api.request<{}>('PUT', `${COMMUNITY_ROUTE(id)}?avoid_empty=true`, {
        json: {
          avatar_url: COMMUNITY_ICON_ROUTE(id),
        },
      })
    }
    history.push('/communities')
  }

  const handleEdit = async () => {
    if (avatarFile) {
      const data = new FormData()
      data.append('file', avatarFile, avatarFile.name)
      await api.request<{}>('POST', COMMUNITY_ICON_ROUTE(id as string), {
        formData: data,
      })
    }
    await api.request<{}>('PUT', COMMUNITY_ROUTE(id as string), {
      json: {
        name,
        desc,
        link,
        kind: platform,
        tags: tags,
        avatar_url: avatarFile ? COMMUNITY_ICON_ROUTE(id as string) : avatarUrl,
        preferred_language: language,
      },
    })
  }

  const handleDelete = async () => {
    if (!window.confirm('Вы точно хотите удалить это сообщество?')) {
      return
    }

    await api.request<{}>('DELETE', COMMUNITY_ROUTE(id as string))
    history.push('/communities')
  }

  if (edit) {
    useEffect(() => {
      fetchData()
    }, [])
  }

  return (
    <Box
      style={{ position: 'relative', top: '-96px', padding: 24 }}
      className="animation-slidein"
    >
      <Field
        name="Название"
        description="Название вашего сообщества"
        required={!edit}
        component={
          <InputOutlined
            /* eslint-disable-next-line @typescript-eslint/no-empty-function */
            onChange={setName}
            value={name}
            fluid
            placeholder="Название сообщества"
          />
        }
      />
      <Field
        name="Аватарка"
        description="Ссылка на картинку аватарки вашего сообщества!"
        component={
          <div className={styles.communityInfoFormAvatarField}>
            <UploadableImageButton
              image={
                avatarUrl ??
                `https://avatars.dicebear.com/api/initials/${name}.svg`
              }
              width={148}
              height={148}
              imageStyle={{ borderRadius: '34px' }}
              onFileUploaded={(f) => {
                const reader = new FileReader()
                reader.readAsDataURL(f)
                reader.onloadend = (_) => {
                  setAvatarUrl(reader.result as string)
                  setAvatarUrlInput('')
                }
                setAvatarFile(f)
              }}
            />
            <p
              className="text-sm text-greyple text-medium"
              style={{
                marginTop: 12,
                fontSize: 12 /* TODO: add to _fonts.scss */,
              }}
            >
              Картинка размером 110x110
            </p>
            <div className={styles.communityInfoAvatarFieldURLWrapper}>
              <InputOutlined
                onChange={setAvatarUrlInput}
                fluid
                placeholder="Введите URL картинки"
                value={avatarUrlInput}
              />
              <Button
                color="brand"
                textColor="white"
                onClick={() => {
                  setAvatarUrl(
                    avatarUrlInput !== '' ? avatarUrlInput : undefined
                  )
                  setAvatarFile(undefined)
                }}
              >
                Выбрать
              </Button>
            </div>
          </div>
        }
      />

      <Field
        name="Описание"
        required={!edit}
        description="Описание вашего сообщества"
        component={
          <TextArea
            onChange={setDesc}
            rows={7}
            fluid
            resize="vertical"
            value={desc}
          />
        }
      />
      <Field
        name="Ссылка"
        required={!edit}
        description="Ссылка на ваше сообщество"
        component={
          <InputOutlined
            /* eslint-disable-next-line @typescript-eslint/no-empty-function */
            onChange={setLink}
            fluid
            value={link ?? 'https://'}
          />
        }
      />
      <Field
        name="Платформа"
        required={!edit}
        description="Выберите основную платформу вашего сообщества"
        component={
          <Select
            options={platforms}
            value={platforms.filter((v) => v.value === platform)}
            placeholder={'Выберите платформу'}
            onChange={(data: any) =>
              setPlatform(data.value as CommunityPlatform)
            }
            formatOptionLabel={({ label, image }: any) => (
              <div className={styles.communityInfoFormSelectOption}>
                <img
                  src={image}
                  className={styles.communityInfoFormSelectImage}
                  alt="Лого платформы"
                />
                <span>{label}</span>
              </div>
            )}
          />
        }
      />
      <Field
        name="Язык"
        required={!edit}
        description="Основной язык вашего сообщества"
        component={
          <Select
            options={languages}
            value={languages.filter((v) => v.value === language)}
            placeholder={'Выберите  язык'}
            onChange={(data: any) => setLanguage(data.value as Flag)}
            formatOptionLabel={({ label, image }: any) => (
              <div className={styles.communityInfoFormSelectOption}>
                <img
                  src={image}
                  className={styles.communityInfoFormSelectImage}
                  alt={label}
                />
                <span>{label}</span>
              </div>
            )}
          />
        }
      />
      <Field
        name="Теги"
        description="Выберите то, что наиболее выражает ваше сообщество"
        component={
          <TagSelect selected={tags} setSelected={setTags} maximum={5} />
        }
      />
      <div className={edit ? styles.communityInfoFormButtonsWrapper : ''}>
        {edit && (
          <Button
            color="status-red"
            styles={{ paddingLeft: '24px', paddingRight: '24px' }}
            onClick={handleDelete}
            className={styles.communityInfoFormDeleteButton}
          >
            Удалить
          </Button>
        )}
        <div
          className={styles.communityInfoFormButtons}
          style={{ marginTop: edit ? 0 : 24 }}
        >
          <Button
            color="background-tetrary"
            textColor="black"
            styles={{ paddingLeft: '24px', paddingRight: '24px' }}
            onClick={() =>
              !edit ? history.push('/') : history.push('/communities')
            }
          >
            {!edit ? 'Отмена' : 'Назад'}
          </Button>
          {!edit ? (
            <Button
              styles={{ paddingLeft: '48px', paddingRight: '48px' }}
              onClick={handleCreate}
            >
              Добавить
            </Button>
          ) : (
            <SaveButton
              styles={{ paddingLeft: '48px', paddingRight: '48px' }}
              onClick={handleEdit}
            >
              Сохранить
            </SaveButton>
          )}
        </div>
      </div>
    </Box>
  )
}

export function NewCommunityForm() {
  return <CommunityInfoFormBase />
}

export function CommunityEditForm(props: CommunityInfoFormProps) {
  return <CommunityInfoFormBase {...props} />
}
