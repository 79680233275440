import React from 'react'
import { Col, Container, Row } from 'react-grid-system'
import Box from '../common/components/Box'
import Banner from '../common/components/Banner'
import { CommunityEditForm } from '../common/components/CommunityInfoForm'
import { useParams } from 'react-router-dom'
import Footer from '../common/components/Footer'

export default function CreateCommunityPage() {
  const { id } = useParams<{ id: string }>()
  return (
    <>
      <Banner
        title="Редактировать сообщество"
        content="Здесь вы можете отредактировать параметры сообщества"
      />
      <Container fluid>
        <Row>
          <Col md={8} xl={6} offset={{ md: 2, xl: 3 }}>
            <CommunityEditForm edit id={id} />
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  )
}
