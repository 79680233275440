import React, { PropsWithChildren, useState } from 'react'
import styles from './Tabs.module.scss'

export type Tab = [string, React.ReactNode]

type TabsProps = {
  tabs: Tab[]
  textSize?: 'sm' | 'md' | 'xl'
  className?: string
  styles?: {
    tabs?: React.CSSProperties
    names?: React.CSSProperties
    tab?: React.CSSProperties
  }
}

export function Tabs({ tabs, textSize, styles: tabsStyles }: TabsProps) {
  const [names, elements] = [tabs.map((v) => v[0]), tabs.map((v) => v[1])]
  const [open, setOpen] = useState<number>(0)
  return (
    <div className={styles.tabs} style={tabsStyles?.tabs}>
      <ul className={styles.tabNames} style={tabsStyles?.names}>
        {names.map((v, idx) => (
          <li
            className={`${textSize ?? 'sm'} ${
              open === idx ? styles.tabNameActive : ''
            }`}
            style={tabsStyles?.tab}
            onClick={() => setOpen(idx)}
          >
            {v}
          </li>
        ))}
      </ul>
      <hr />
      {elements[open]}
    </div>
  )
}
