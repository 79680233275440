import React from 'react'
import { PropsWithClassesAndStyle } from '../utils/style'
import stylesBox from './Box.module.scss'

export type BoxProps = React.PropsWithChildren<
  PropsWithClassesAndStyle<{
    doUpper?: boolean
  }>
>

export default function Box({ children, doUpper, style, className }: BoxProps) {
  return (
    <div
      className={`${stylesBox.box} ${doUpper ? stylesBox.inline : ''} shadow ${
        className ?? ''
      }`}
      style={style}
    >
      {children}
    </div>
  )
}
