import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import Banner from '../common/components/Banner'
import Footer from '../common/components/Footer'
import styles from './AboutPage.module.scss'

function Section({ name, first, children }: PropsWithChildren<{ name: string; first?: boolean }>) {
  return <Row style={{ marginTop: 48 }}><Col offset={{ md: 2 }} md={8}>
    {first ? <h1 className={classNames('text-xl text-brand')}>{name}</h1> : <h3 className={classNames('text-lg')}>{name}</h3>}
    <p className={styles.sectionContent}>{children}</p>
  </Col></Row>
}

export function AboutPage() {
  return <>
    {/* <Banner title="О нас" content='Помогаем в поиске интересных Вам сообществ' /> */}
    <Banner title="Помогаем искать" content='Hyperia это платформа для продвижения вашего сообщества и поиска других интересных вам сообществ' />
    <Container style={{ paddingBottom: 48 }}>
      <Section first name='Наша история'>
        <b>Hyperia</b> создавалась для решения задач нахождения и поиска
        сообществ, которые нравятся пользователям и где им будет
        комфортно
      </Section>
      <Section name='Как мы сюда попали'>
        Всё началось в 2019-ом, с нашей общей идеи с Владом, одним из моих
        друзей — создать место, где каждый может прорекламировать свое
        сообщество. И не просто прорекламировать, добавив его на список
        серверов, в группу ВК, а показать его единомышленникам: таким же
        искателям приключений, эмоций, и места, где интересно, где можно
        завести друзей, найти свое призвание, или просто проводить хорошо
        время.
      </Section>
      <Section name='Публичное тестирование'>
        По прошествию года мы начали собирать аудиторию для публичного
        тестирования: разработчики, владельцы Discord серверов и просто
        хорошие люди. Следующие два года ушли на построение правильной
        экосистемы, выработку дизайна, концепций и дополнений к основной идее.
      </Section>
      <Section name='В настоящее время'>
        В настоящее время мы работаем над улучшением Hyperia и собираемся
        сделать полноценный публичный релиз, учтя все недочёты и проблемы.
        Заглядывая в будущее, мы планируем продолжать улучшать нашу
        изначальную задумку — дать людям возможность продвигать и находить
        интересные им сообщества.
      </Section>
    </Container>
    <Footer />
  </>
}
