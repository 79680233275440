import React, { useEffect } from 'react'
import { TagTypes } from '../features/communities/tags'
import { Select } from './SelectWrapper'

const tagOptions = [
  { label: 'IT', value: 'it' },
  { label: 'Аниме', value: 'anime' },
  { label: 'Игры', value: 'games' },
  { label: 'Искусство', value: 'art' },
  { label: 'Музыка', value: 'music' },
  { label: 'Общение', value: 'chatting' },
  { label: 'RolePlay', value: 'roleplay' },
  { label: 'Сообщество', value: 'community' },
  { label: '18+', value: 'nsfw' },
  { label: 'Технологии', value: 'technology' },
  { label: 'Политика', value: 'politics' },
  { label: 'Фильмы', value: 'films' },
  { label: 'Юмор', value: 'humor' },
  { label: 'Бизнес', value: 'business' },
  { label: 'Другое', value: 'other' },
]

type TagSelectProps = {
  selected?: TagTypes[]
  setSelected: (v: TagTypes[]) => void
  maximum: number
}

export function TagSelect({ selected, setSelected, maximum }: TagSelectProps) {
  maximum = maximum ?? 5

  return (
    <Select
      options={(selected?.length || 0) <= maximum ? tagOptions : []}
      isMulti
      onChange={(v) => {
        setSelected(
          (v as ReadonlyArray<unknown>).map((t: any) => t.value as TagTypes)
        )
      }}
      value={selected?.map((v) => tagOptions.filter((t) => t.value === v)[0])}
      styles={{
        container: (provided, state) => ({
          ...provided,
          width: '100%',
        }),
        multiValue: (provided, state) => ({
          ...provided,
          borderRadius: '30px',
          padding: '2px 5px',
          // color: 'hsl(0, 0%, 80%)',
          backgroundColor: '#7289FA',
        }),
        multiValueLabel: (provided, state) => ({
          ...provided,
          color: 'white',
        }),
        multiValueRemove: (provided, state) => {
          return {
            ...provided,
            borderRadius: '30px',
            // color: 'hsl(0, 0%, 80%)',
            color: '#E0E0E0',
            '&: hover': {
              color: 'white',
              transform: 'scale(110%)',
              backgroundColor: 'transparent',
            }
          }
        },
      }}
      noOptionsMessage={() =>
        selected && selected.length > maximum
          ? 'Максимальное количество тегов'
          : 'Не осталось тегов'
      }
      placeholder={'Введите или выберите теги'}
    />
  )
}
