import React, { PropsWithChildren, useState, useEffect } from 'react'
import classNames from 'classnames'
import styles from './FullscreenLoader.module.scss'
import Logo from './Logo'
import { Hidden } from 'react-grid-system'

export function FullscreenLoader({ overlay, text, animate }: { overlay?: boolean; text: string; animate?: boolean }) {
  animate = animate ?? true
  return <div className={classNames(styles.fullscreenLoader, { [styles.fullscreenLoaderOverlay]: overlay })}>
    <div className={classNames(styles.fullscreenLoaderLogo, { [styles.fullscreenLoaderLogoAnimated]: animate })}>
      <Logo variant="alternate" style={{ maxHeight: 38 }} />
    </div>
    {/* <p className="text-white text-lg">Помогаем в поиске</p> */}
    <p className="text-white text-lg">{text}</p>
    <div className={classNames(styles.fullscreenLoaderBranding, 'text-sm', 'text-white')}>
      <Logo variant="alternate" style={{ maxWidth: 15 }} />
      <b>Hyperia{/* . */}</b>
      <Hidden xs sm><span style={{ color: '#808080' }}>Looking into the future</span></Hidden>
    </div>
  </div>
}

export function FullscreenLoaderDelay({ children, delay = 3000 }: PropsWithChildren<{ delay?: number }>) {
  delay = Math.max(delay, 2600)
  // const [accessToken] = useAuthState()

  // if (process.env.NODE_ENV !== 'production') {
  //   return <>{children}</>
  // }

  // const [animation, setAnimation] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(true)
  const [text, setText] = useState<string>('Looking into the future') // 'Смотря в будущее')
  useEffect(() => {
    // NOTE: 2500 is a hard timeout which resembles appearing animation and half of the pulsing animation.
    setTimeout(() => {
      setText('Helping you search')
      // setAnimation(false)
    }, 2500)

    setTimeout(() => setLoading(false), delay)
  }, [])

  return loading ? <div className="force-no-scrollbar">
    <FullscreenLoader overlay text={text} animate={true} />
    {children}
  </div> : <>{children}</>
}
