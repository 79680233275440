import React from 'react'

import LoginWindow from './components/LoginWindow'
import styles from './LoginPage.module.scss'

export default function LoginPage() {
  return (
    <div className={`${styles.loginWindowWrapper} fill-height`}>
      <LoginWindow />
    </div>
  )
}
