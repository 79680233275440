export const Tags = {
  it: 'IT',
  anime: 'Аниме',
  games: 'Игры',
  art: 'Исскуство',
  music: 'Музыка',
  chatting: 'Общение',
  roleplay: 'RolePlay',
  community: 'Сообщество',
  nsfw: '18+',
  technology: 'Технологии',
  politics: 'Политика',
  films: 'Фильмы',
  humor: 'Юмор',
  business: 'Бизнес',
  other: 'Другое',
}

export type TagTypes = keyof typeof Tags
