import { useReducer } from 'react'
import {
  AuthState,
  defaultAuthState,
  resetAuthState,
  saveAuthState,
} from './state'
import { Action } from '../../defs'
import { AuthActionTypes, RESET_ACTION, SET_PAIR_ACTION } from './types'

function authReducer(state: AuthState, action: Action<AuthActionTypes>) {
  switch (action.type) {
    case SET_PAIR_ACTION:
      state = {
        ...state,
        accessToken: action.value.accessToken,
        refreshToken: action.value.refreshToken,
      }
      saveAuthState(state)
      return state
    case RESET_ACTION:
      resetAuthState()
      return defaultAuthState
  }

  return state
}

export const useAuthReducer = () => useReducer(authReducer, defaultAuthState)
