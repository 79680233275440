import React, { PropsWithChildren, useEffect, useState } from 'react'
import Box from '../../common/components/Box'
import SkeletonElement, {
  SkeletonArticle,
} from '../../common/components/Skeleton'
import styles from './CommunityCard.module.scss'
import wumpus from '../assets/community-banner.svg'
import {
  BadgeIcons,
  BadgeNames,
  Badges,
  BadgeType,
  Community,
  Flag,
} from '../../common/features/communities'
import { COMMUNITY_ROUTE } from '../../common/features/api'
import { useAPI } from '../../common/features/api/provider'
import Button, { LinkButton } from '../../common/components/Button'
import { useHistory } from 'react-router-dom'
import star from '../../common/assets/badges/star.svg'
import dev from '../../common/assets/badges/dev.svg'
import useFlag from '../../common/hooks/useFlag'
import { PropsWithStyle } from '../../common/utils/style'
import { resolveIcon } from '../../common/features/communities/avatar'

function loadingClass(className: string): string {
  return `${className}-loading`
}

export function CommunityCardLoading() {
  return (
    <Box className={styles.communityCard}>
      <SkeletonElement className={styles.communityCardBanner} />
      <div className={styles.communityCardBody}>
        <SkeletonElement animate className={styles.communityCardAvatar} />
        <div className={styles.communityCardInfoWrapper}>
          <SkeletonElement
            className={`${styles.communityCardBadges} ${styles.communityCardBadgesSkeleton}`}
          />
          <SkeletonElement
            className={`${styles.communityCardBadges} ${styles.communityCardBadgesSkeleton}`}
          />
        </div>
        <SkeletonElement
          animate
          className={`${styles.communityCardTitle} text-bold`}
          style={{ width: 140, height: 23 }}
        />
        <SkeletonArticle
          className={styles.communityCardDescription}
          lines={4}
          lineMargin={18 - 15}
          elementProps={{ style: { borderRadius: 8 }, animate: true }}
        />
        <div className={styles.communityCardButtonWrapper}>
          <SkeletonElement style={{ borderRadius: 50, height: 43 }} />
          <SkeletonElement style={{ borderRadius: 50, height: 43 }} />
        </div>
      </div>
    </Box>
  )
}

export type CommunityBadgesChipProps = PropsWithStyle<{
  badges: BadgeType[]
}>
export function CommunityBadgesChip({
  badges,
  style,
}: CommunityBadgesChipProps) {
  return (
    <div style={style} className={styles.communityCardBadges}>
      {badges.slice(0, 2).map((v) => (
        <img src={BadgeIcons[v]} alt={BadgeNames[v]} />
      ))}
    </div>
  )
}

export function CommunityFlag({ flag }: { flag: Flag }) {
  const img = useFlag(flag)
  return <img src={img} alt={flag} className={styles.communityCardFlag} />
}

export type CommunityCardLoadedProps = {
  id: string
  avatarUrl: string
  name: string
  badges: BadgeType[]
  desc: string
  country: Flag
  link: string
}

export function CommunityCardLoaded({
  id,
  avatarUrl,
  name,
  desc,
  link,
  badges,
  country,
}: CommunityCardLoadedProps) {
  const history = useHistory()
  return (
    <Box className={styles.communityCard} key={`community-card-${id}`}>
      <div
        className={styles.communityCardBanner}
        style={{
          backgroundImage: `url(${wumpus})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          display: 'block',
        }}
      />
      <article className={styles.communityCardBody}>
        <div className={styles.communityCardInfoWrapper}>
          <CommunityBadgesChip
            badges={badges}
            style={{ visibility: badges.length > 0 ? 'initial' : 'hidden' }}
          />
          <CommunityFlag flag={country} />
        </div>
        <div
          className={`${styles.communityCardAvatar} shadow`}
          style={{
            backgroundImage: `url(${resolveIcon({
              name,
              avatar_url: avatarUrl,
            })}`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            display: 'block',
            backgroundColor: 'white',
          }}
        />
        <h1 className={`${styles.communityCardTitle} text-bold`}>{name}</h1>
        <p className={styles.communityCardDescription}>{desc}</p>
        <div className={styles.communityCardButtonWrapper}>
          <LinkButton textColor="black" color="light-gray" href={link} external>
            Зайти
          </LinkButton>
          <LinkButton href={`/community/${id}`}>Подробнее</LinkButton>
        </div>
      </article>
    </Box>
  )
}

export default function CommnuityCard({ id }: { id: string }) {
  const [data, setData] = useState<Community>()
  const api = useAPI()
  const fetchData = async () => {
    setData((await api.request<Community>('GET', COMMUNITY_ROUTE(id))).data)
  }

  useEffect(() => {
    fetchData()
  }, [])

  // eslint-disable-next-line multiline-ternary
  return data ? (
    <CommunityCardLoaded
      {...data}
      avatarUrl={data.avatar_url}
      badges={Badges.reduce(
        (p, v) => (data.badges & v ? [...p, v] : p),
        [] as BadgeType[]
      )}
      country={data.preferred_language}
    />
  ) : (
    <CommunityCardLoading />
  )
}
