import React, { useEffect, useState } from 'react'
import Banner from '../common/components/Banner'
import wumpus from '../common/assets/wumpus.svg'
import { Container, Row, Col } from 'react-grid-system'
import SearchBox, { SearchBoxFiltersData } from '../common/components/SearchBox'
import { SearchTypes } from '../common/features/communities/search'
import {
  SearchCommunity,
  Badges,
  BadgeType,
  Community,
  TagTypes,
} from '../common/features/communities'
import { useAPI } from '../common/features/api/provider'
import {
  COMMUNITY_ROUTE,
  RECOMMEND_ROUTE,
  USER_ROUTE,
} from '../common/features/api'
import { useUser } from '../common/features/users/provider'
import { User } from '../common/features/users'
import CommunityCard, {
  CommunityCardLoaded,
  CommunityCardLoadedProps,
  CommunityCardLoading,
} from './components/CommunityCard'
import { useAuthState } from '../common/features/auth/state/provider'
import MeiliSearch from 'meilisearch'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { PropsWithClassesAndStyle } from '../common/utils/style'
import querystring from 'query-string'
import { FullscreenLoaderDelay, FullscreenLoader } from '../common/components/FullscreenLoader'
import Footer from '../common/components/Footer'

const meiliClient = new MeiliSearch({
  host: process.env.REACT_APP_MEILI_HOST as string,
  apiKey: process.env.REACT_APP_MEILI_API_KEY as string,
}).index(
  process.env.NODE_ENV === 'production' ? 'communities' : 'dev_communities'
)

export function FeedEntry({
  id,
  name,
  desc,
  avatarUrl: avatar_url,
  link,
  badges,
  style,
  className,
}: PropsWithClassesAndStyle<
  Omit<CommunityCardLoadedProps, 'badges'> & { badges: number }
>) {
  return (
    <Col
      sm={12}
      md={6}
      lg={6}
      xl={4}
      style={style}
      className={className}
      key={`community-${id}`}
    >
      <CommunityCardLoaded
        id={id}
        name={name}
        desc={desc}
        avatarUrl={avatar_url}
        link={link}
        badges={Badges.reduce(
          (p, v) => (badges & v ? [...p, v] : p),
          [] as BadgeType[]
        )}
        country="ru"
      />
    </Col>
  )
}

export default function Feed() {
  const [communities, setCommunities] = useState<Community[]>()
  const [tags, setTags] = useState<TagTypes[]>([])
  const api = useAPI()
  const [me] = useUser()
  const [isSearchResult, setIsSearchResult] = useState(false)
  const [{ accessToken }] = useAuthState()
  const fetchData = async () => {
    const query = tags.length ? `?tags=${tags.join('+')}` : ''
    setCommunities(
      (
        await api.request<Community[]>(
          'GET',
          `${RECOMMEND_ROUTE((me as User).id)}${query}`,
          { omitToken: true }
        )
      ).data
    )
  }

  const [query, setQuery] = useState<string>('')
  const history = useHistory()
  const fetchSearchResults = async (query: string) => {
    // console.log('oh yeah', query)
    const { hits } = await meiliClient.searchGet<SearchCommunity>(query)
    setCommunities(
      hits.map(
        ({ id, name, desc, avatarUrl, link }) =>
        ({
          id,
          name,
          desc,
          link,
          avatar_url: avatarUrl,
        } as Community)
      )
    )
    setIsSearchResult(true)
  }

  const handleFilters = ({ tags: newTags }: SearchBoxFiltersData) => {
    if (query) return
    setTags(newTags)
    fetchData()
  }

  const { search } = useLocation()
  useEffect(() => {
    console.log('search: ', search)
    const { q } = querystring.parse(search)
    if (q) {
      setQuery(q as string)
      fetchSearchResults(q as string)
    } else if (me) fetchData()
  }, [me, search])

  const [type, setType] = useState<SearchTypes>('recommended')

  return (
    <>
      <Banner
        image={wumpus}
        content="Найди сообщество под свои предпочтения. У нас только честный топ и рейтинги от участников."
        title="Сообщества на любой вкус"
      />

      <Container>
        <Row key="searchbox-wrapper">
          <Col md={8} xl={6} offset={{ md: 2, xl: 3 }} key="searchbox">
            <SearchBox
              query={query}
              setQuery={(v) => setQuery(v)}
              setSearchType={setType}
              onSubmit={() => fetchSearchResults(query)}
              onFiltersApply={handleFilters}
              onFiltersClear={() => handleFilters({ tags: [] })}
              style={{ top: -24 * 3 }}
              doUpper
            />
          </Col>
        </Row>
      </Container>
      <Container style={{ marginTop: '20px' }}>
        <Row key="communities-row">
          {me || accessToken !== '' || isSearchResult ? (
            communities ? (
              communities.length ? (
                communities.map(
                  ({ id, name, desc, avatar_url, link, badges }, idx) => (
                    <FeedEntry
                      id={id}
                      name={name}
                      desc={desc}
                      avatarUrl={avatar_url}
                      link={link}
                      badges={badges}
                      country="ru"
                      style={{ marginTop: idx > 2 ? 24 : 0 }}
                    />
                  )
                )
              ) : (
                <Col
                  sm={12}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <h1 className="text-md text-bold">Ничего не нашлось</h1>
                </Col>
              )
            ) : (
              [...Array(20).keys()].map((idx) => (
                <Col
                  sm={12}
                  md={6}
                  lg={6}
                  xl={4}
                  style={{ marginTop: idx > 2 ? 24 : 0 }}
                  key={`community-${idx}`}
                >
                  <CommunityCardLoading />
                </Col>
              ))
            )
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
                rowGap: 12,
              }}
            >
              {/* TODO: localStorage for faster identification */}
              <h1 className="text-md text-bold">Зарегистрируйтесь!</h1>
              <p className="text-greyple text-sm text-normal">
                Рекоммендации доступны только для зарегистрированных
                пользователей. Но вы все еще можете воспользоваться поиском
              </p>
            </div>
          )}
        </Row>
      </Container>
      <Footer compact />
    </>
  )
}

// export default function FeedLoading() {
//   return <FullscreenLoaderDelay>
//     <Feed />
//   </FullscreenLoaderDelay>
// }
