import React, { PropsWithChildren } from 'react'
import Banner from '../common/components/Banner'
import wumpus from '../common/assets/wumpus.svg'
import Box from '../common/components/Box'
import { Col, Container, Row } from 'react-grid-system'
import { QnACard } from './components/QnACard'
import Footer from '../common/components/Footer'

export function QnACardList({ children }: PropsWithChildren<{}>) {
  return (
    <>
      {React.Children.map(children, (child, idx) => (
        <Row style={idx > 0 ? { marginTop: 40 } : {}}>
          <Col md={10} xl={8} offset={{ md: 1, xl: 2 }}>
            {child}
          </Col>
        </Row>
      ))}
    </>
  )
}

export function QnAPage() {
  return (
    <>
      <Banner
        title="Q&A"
        content="Здесь вы найдете ответы на все часто задаваемые вопросы по поводу нашего проекта"
        image={wumpus}
      />
      <Container style={{ top: '-48px' }}>
        <QnACardList>
          <QnACard question="Добавление сообщества">
            Вы можете добавить любое сообщество, с условием того, что оно в
            интернете. Поддерживаемые типы сообществ: Discord сервер, Twitter
            аккаунт, группа Facebook и простой вебсайт.
          </QnACard>
          <QnACard question="Почему мое сообщество в самом низу?">
            Потому что еще не набралось достаточного количества пользователей,
            посетивших ваше сообщество, для выставления его более точной позиции
            в рейтинге. Небольшой совет: чем меньше тегов — тем проще ваше
            сообщество найдут. Не пытайтесь спамить теги.
          </QnACard>
          <QnACard question="У меня идет бесконечная загрузка сообществ">
            Первым делом — проверьте &quot;Статус&quot;, там будут показаны
            какие-либо события связанные с неполадками или техническими
            работами. Так же, на текущий момент рекомендации показываются только
            зарегистрированным пользователям, поэтому Вы можете получать такую
            бесконечную загрузку, но Вы все еще можете воспользоваться поиском.
          </QnACard>
          <QnACard question="Могу ли я войти через X?">
            На текущий момент такой возможности не предоставляется, но Вы можете
            создать наш аккаунт и привязать к нему аккаунт на платформе X.
          </QnACard>

          <QnACard question="Это бесплатно?">
            На текущий момент все это — бесплатно. В будущем будут подписки, но
            они будут влиять только на косметику.
          </QnACard>
          <QnACard question="Как работают рекомендации">
            Вы заходите на интересные вам сообщества — мы стараемся подобрать
            вам подобные. Так же Вы можете указать ваши предпочтения в
            настройках.
          </QnACard>
        </QnACardList>
      </Container>
      <Footer />
    </>
  )
}
